import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from '../../../Redux/filterSlice';
import { getCheckoutPageDetails, getFormatDate, getTodayDate } from '../../../utility';
import Dropdown from 'react-bootstrap/Dropdown';
import Countinc from '../../../Components/Countinc';
import useRazorpay from "react-razorpay";
import "../../../Style.css";
import Addon from '../../../Components/Addon';
import Axios from '../../../Axios';
import { updateLoader } from '../../../Redux/loaderSlice';
import GetStaticValue from '../../../utility/GetStaticValue';
import axios from 'axios';
import { toast } from 'react-toastify';


const Checkout = () => {
    const dispatch = useDispatch();
    const [Razorpay, isLoaded] = useRazorpay();
    const filers = useSelector((state) => state.filers);
    const { beforeToday } = DateRangePicker;
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;
    const [resp, setResp] = useState(null);
    const [addons, setAddons] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectedAddonsPrice, setSelectedAddonsPrice] = useState(0);
    const [new_order_id, setOrderId] = useState("");
    const common = useSelector((state) => state.common);
    const loader = useSelector(state => state.loader);
    const [capacity, setCapacity] = useState(10);
    const [error, setError] = useState('');
    const [paymentLink, setPaymentLink] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState([new Date(filers.check_in), new Date(filers.check_out)]);

    const createOrder = async (e, new_order_id) => {
        const phoneNumber = localStorage.getItem('phone');

        if (!phoneNumber) {
            navigate('/user/profile');
            toast.error("Please update phone number");
            return;
        }
        //hijrri
        try {
            const params = {
                property_id: e,
                check_in: filers.check_in, // Assuming filers is defined elsewhere
                check_out: filers.check_out,

                addons: selectedAddons, // Assuming selectedAddons is defined elsewhere
                adults: filers.adults,
                children: filers.children,
                infants: filers.infants,
                rooms: filers.rooms,
                // Use the order_id from state if needed
            };

            console.log(params);
            dispatch(updateLoader({ loader: true }));

            const resp = await Axios.post('/paypal/order/create', params);
            console.log(resp.data.data.redirect_url);
            setPaymentLink(resp.data.data.redirect_url);
            dispatch(updateLoader({ loader: false }));

            // Redirect to PayPal payment page
            window.location.href = resp.data.data.redirect_url;
            // Close current window/tab
            window.close();
        } catch (error) {
            toast.error(error.response.data.data.message);
            dispatch(updateLoader({ loader: false }));
        }
    };


    const getString = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/');
            if (resp.data.code === "EC200") {
                console.log(resp.data.data.order_id, "order_id");
                setOrderId(resp.data.data.order_id);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    };

    useEffect(() => {
        getString();
    }, []); // Empty dependency array ensures this runs once after the initial render


    const handleAddonSelection = (addon) => {
        // Check if the addon is already selected
        const index = selectedAddons.indexOf(addon);
        if (index !== -1) {
            // If selected, remove it
            const updatedAddons = [...selectedAddons];
            console.log(updatedAddons)
            updatedAddons.splice(index, 1);
            setSelectedAddons(updatedAddons);
            // Also subtract its price from selectedAddonsPrice
            setSelectedAddonsPrice(selectedAddonsPrice - addon.price);
        } else {
            // If not selected, add it
            setSelectedAddons([...selectedAddons, addon]);
            // Also add its price to selectedAddonsPrice
            setSelectedAddonsPrice(selectedAddonsPrice + addon.price);
        }
    };
    const handlePayment = useCallback((orderId, new_order_id) => {
        const options = {
            key: "rzp_test_ZE3RjMWleiaCcJ",
            name: "Property Rental",
            description: "Book Your Property",
            image: "http://192.168.1.11:8080/amenitisFile/logoheader.png",
            order_id: orderId,
            handler: (res) => {
                console.log(res);
                updateBookingStatus(res, new_order_id);
            },
            prefill: {
                name: "Yashwant gupta",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [Razorpay]);


    const getData = async (e) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/addons/' + e)
            if (resp.data.code == "EC200") {
                setAddons(resp.data.data.addons);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response)
            } else {
                console.log(error.message);
            }
        }
    }

    const checkAvailability = async (e, filers) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const params = {
                property_id: e.id,
                check_in: filers.check_in,
                check_out: filers.check_out,
                adults: filers.adults,
                children: filers.children,
                infants: filers.infants,
                rooms: filers.rooms,
            }
            console.log('params ', filers)
            const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/check-availability/`, params)
            if (resp.data.code == "EC200") {
                console.log(resp.data.data);
                setResp(resp.data.data)
                setErrorMsg(resp.data.data.message)

            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response)
            } else {
                console.log(error.message);
            }
        }
    }
    useEffect(() => {
        checkAvailability(data, filers)
    }, [filers, data])


    useEffect(() => {
        getData(data.id);
    }, [data])

    const updateBookingStatus = async (paymentResponse, new_order_id) => {
        try {
            dispatch(updateLoader({ loader: true }));

            const params = {
                paymentId: paymentResponse.razorpay_payment_id,
                service_fee: 50,
                order_id: new_order_id,

            };

            const resp = await Axios.post('/book', params);
            dispatch(updateLoader({ loader: false }));
            navigate(`/thankyou/${new_order_id}`);
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            console.log('Error updating booking status:', error);
        }
    }; useEffect(() => {
        const storedDateRange = JSON.parse(localStorage.getItem('dateRange'));
        if (storedDateRange) {
            setSelectedDateRange([new Date(storedDateRange[0]), new Date(storedDateRange[1])]);
            dispatch(updateFilters({ check_in: getFormatDate(new Date(storedDateRange[0])), check_out: getFormatDate(new Date(storedDateRange[1])) }));
        } else {
            setSelectedDateRange([new Date(filers.check_in), new Date(filers.check_out)]);
        }
    }, [dispatch, filers.check_in, filers.check_out]);

    const handleDateChange = (range) => {
        if (range && range.length === 2) {
            const [startDate, endDate] = range;

            // Normalize dates to remove time and compare only dates
            const start = new Date(startDate.toDateString());
            const end = new Date(endDate.toDateString());

            if (start.getTime() === end.getTime()) {
                alert("Check-in and check-out dates cannot be the same. Please select different dates.");
                return;
            }

            // Proceed if dates are different
            setSelectedDateRange(range);
            dispatch(updateFilters({ check_in: getFormatDate(range[0]), check_out: getFormatDate(range[1]) }));
            localStorage.setItem('dateRange', JSON.stringify(range));
        }
    };

    function useWindowWidth() {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    }

    const handleUpdate = (e) => {
        if (e < 1) {
            setError(``);
        } else {
            setError('');
            dispatch(updateFilters({ rooms: e }));
        }
    };
    const handleUpdateGuest = (e) => {
        if (e < 1) {
            setError(``);
        } else {
            setError('');
            dispatch(updateFilters({ adults: e }));
        }
    };
    const windowWidth = useWindowWidth();
    const showOneCalendar = windowWidth <= 481;
    return (
        <>
            {data ? (
                <div className="checkout_main_wrapper">
                    <div className="container">
                        <div className="rentel_main_wrapperbox_checkout">
                            <div className="checkout_pages_backtitle">
                                <h3>
                                    <Link to={'/product/' + data.id} style={{ color: 'inherit', textDecoration: 'auto' }}>
                                        <img src="/icon/arrow.png" className="img-fluid" alt="logo" />
                                        Confirm and pay
                                    </Link> </h3>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="yourtrip_wrapper_box">
                                        <div className="checkoutpage_title_box">Your Trip</div>
                                        <div className="yourtrip_box">
                                            <div className="date_box_left">
                                                <div className="flex_div_checkout">
                                                    <div className="classcheckout_dateicon">
                                                        <img src="/icon/Vectordate.png" className="img-fluid" alt="logo" />
                                                    </div>
                                                    <div className="datetest_title">
                                                        Dates
                                                        <div className="date_title_month">
                                                            <DateRangePicker
                                                                size="xs"
                                                                placeholder={'Please select check in/out date'}
                                                                format="dd MMM yy"
                                                                character={" - "}
                                                                showHeader={false}
                                                                showOneCalendar={showOneCalendar}
                                                                onOk={handleDateChange}
                                                                shouldDisableDate={beforeToday()}
                                                                cleanable={false}
                                                                value={selectedDateRange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Dropdown>
                                            <div className="yourtrip_box border-0">
                                                <div className="date_box_left ml-3">
                                                    <div className="flex_div_checkout" style={{ marginLeft: 10 }}>
                                                        <div className="classcheckout_dateicond">
                                                            <img src="/icon/checkoutuser.png" className="img-fluid" alt="logo" style={{ width: 15, height: 15, maxWidth: 15 }} />
                                                        </div>
                                                        <div className="datetest_title">
                                                            <span style={{ margin: 5 }}>Guests</span>
                                                            <div className="checkout" style={{ padding: 1, marginLeft: -2 }}>
                                                                {/* <Dropdown.Toggle variant="none" style={{ border: "none" }}> */}
                                                                <div className='gust-text'>
                                                                    {`Adults - ${filers.adults}, Children - ${filers.children}, Infants - ${filers.infants}`}
                                                                </div>

                                                                {/* </Dropdown.Toggle> */}
                                                                <Dropdown.Menu style={{ width: 300 }}>
                                                                    <Countinc title="Adults" update={handleUpdateGuest} value={filers.adults} />
                                                                    <Countinc title="Children" update={(e) => dispatch(updateFilters({ children: e }))} value={filers.children} />
                                                                    <Countinc title="Infants" update={(e) => dispatch(updateFilters({ infants: e }))} value={filers.infants} />
                                                                    {/* <Countinc title="Room" update={handleUpdate} value={filers.rooms} /> */}
                                                                    {/* <Countinc title="Bedroom" update={handleUpdate} value={filers.bedroom} /> */}
                                                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                                                </Dropdown.Menu>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="date_box_rights">
                                                    <Dropdown.Toggle variant="none" style={{ border: "none" }}>
                                                        <div className="editpencile_icon">
                                                            <img src="/icon/editpencile.png" alt="logo" className="img-fluid" />
                                                        </div>
                                                    </Dropdown.Toggle>
                                                </div>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    {resp && resp.status == 1 ? (
                                        <>
                                            {console.log(resp, 'error')}

                                            <div className="yourtrip_wrapper_box d-none">
                                                <div className="checkoutpage_title_box">Choose Payment Mode</div>
                                                <div className="payment_check">
                                                    <div className="check_paypal">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input "
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault1"
                                                            />
                                                            <label
                                                                className="form-check-label payment_checkbox"
                                                                htmlFor="flexRadioDefault1"
                                                            >
                                                                Credit Card
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="check_paypal">
                                                        <div className="cardname_wrapper">
                                                            <div className="cardname">
                                                                <img
                                                                    src="/icon/Visa-Logo-PNG-Image.png"
                                                                    className="img-fluid" alt="logo"
                                                                />
                                                            </div>
                                                            <div className="cardname">
                                                                <img src="/icon/mastercard.png" className="img-fluid" alt="logo" />
                                                            </div>
                                                            <div className="cardname">
                                                                <img
                                                                    src="/icon/american-express.png"
                                                                    className="img-fluid" alt="logo"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group payment_input">
                                                    <input
                                                        type="text"
                                                        className=" pay_input_placeholder"
                                                        placeholder="Card Number"
                                                    />
                                                </div>
                                                <div className="form-row payment_input">
                                                    <div className="form-group form-column">
                                                        <input
                                                            type="text"
                                                            className=" pay_input_placeholder"
                                                            placeholder="Expiry Date"
                                                        />
                                                    </div>
                                                    <div className="form-group form-column">
                                                        <input
                                                            type="text"
                                                            className=" pay_input_placeholder"
                                                            placeholder="Security Code"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group payment_input">
                                                    <input
                                                        type="text"
                                                        className="pay_input_placeholder"
                                                        placeholder="Card Holder Name"
                                                    />
                                                </div>
                                                <div className="payment_check">
                                                    <div className="check_paypal">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input "
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault1"
                                                            />
                                                            <label
                                                                className="form-check-label payment_checkbox"
                                                                htmlFor="flexRadioDefault1"
                                                            >
                                                                Pay pal
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="check_paypal">
                                                        <img src="/icon/PayPal 1.png" className="img-fluid" alt="logo" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="yourtrip_wrapper_box desktop_cenclelation">
                                                <div className="checkoutpage_title_box">Cancellation policy</div>
                                                <div className="cancelpolcey">
                                                    <strong style={{ color: "#000000" }}>
                                                       
                                                    </strong>
                                                    If you cancel your booking within 30 days I will refund full amount of your booking but if you cancel your booking after 30 days then I will charge 5%
                                                </div>
                                            </div>
                                            <div className='desktop_checkout_btn'>
                                                <div className="yourtrip_wrapper_box_btn"
                                                    onClick={() => createOrder(data.id, new_order_id)}>
                                                    <button className="payment_confrim_btn">Confirm &amp; Pay</button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (

                                        <div className="checkout_error_wrapper_box">
                                            <p className="checkout_error_text" >{!loader.loader} {errorMsg}</p>
                                        </div>
                                    )}
                                </div>
                                {resp && resp.status == 1 && (
                                    <div className="col-md-5">
                                        <div className="yourtrip_wrapper_box1 ">
                                            <div className="confrim_product_wrapper">
                                                <div className="confrim_inner_wrapper border-0" >
                                                    <div className="rental_checkoutimg">
                                                        <img src={data.image} className="img-fluid" alt="logo" />
                                                    </div>
                                                    <div className="rental_checkout_title">
                                                        <div className='checkoutporductname'><p>{data.name} </p></div>
                                                        <div className='checkoutprice_wrapper'><div className="ruppee_text">{common.currency} {data.price}</div></div>
                                                        <span className="checkout_pageribben">
                                                            {" "}
                                                            {data.rating} <i className="fa fa-star" />
                                                        </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            addons.length ? (
                                                <div className="reateprice_cehckout yourtrip_wrapper_box1">
                                                    <div className="pricedetails">Add-ons</div>
                                                    <Addon value={addons} getAddons={(selectedAddons, price) => { setSelectedAddons(selectedAddons); setSelectedAddonsPrice(price) }} />
                                                </div>
                                            ) : null
                                        }
                                        <div className="reateprice_cehckout yourtrip_wrapper_box1">
                                            <div className="pricedetails">Price Details</div>
                                            <div className="pricebox_wrapper">
                                                <div className="priductname">{common.currency}{data.price} x {resp?.data.differenceDays} Nights</div>
                                                <div className="priductname">{common.currency} {resp?.data.totalPrice}</div>
                                            </div>

                                            <div className={`pricebox_wrapper ${resp?.data.discountAmount === 0 ? 'd-none' : ''}`}>
                                                <div className="priductname">Instant Discount - {resp?.data.discount}</div>
                                                <div className="priductname">-{common.currency} {resp?.data.discountAmount}</div>
                                            </div>

                                            {selectedAddons.length > 0 && (
                                                <div className="pricebox_wrapper">
                                                    <div className="priductname">Selected Addons</div>
                                                    <div className="priductname">{common.currency} {selectedAddonsPrice}</div>
                                                </div>)}
                                            <div className="pricebox_wrapper">
                                                <div className="priductname">Loreum Ispum Service fee</div>
                                                <div className="priductname">{common.currency} {resp?.data.servicefee}</div>
                                            </div>
                                            <div className="pricebox_wrapper">
                                                <div className="priductname">Tax</div>
                                                <div className="priductname">{common.currency} {resp?.data.tax}</div>
                                            </div>
                                            <div className="totalprice_wrapper_checkout">
                                                <div className="totalprice_inner_checkout">
                                                    <div className="total_price">Total Price</div>
                                                    <div className="total_price">{common.currency} {parseInt(resp.data.payablePrice) + parseInt(selectedAddonsPrice)}</div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="yourtrip_wrapper_box mobile_cenclelation">
                                            <div className="checkoutpage_title_box">Cancellation policy</div>
                                            <div className="cancelpolcey">
                                                <strong style={{ color: "#000000" }}>
                                                    Free cancellation before 12:00 pm on 2 Mar.
                                                </strong>
                                                Cancel before check-in on 3 Mar for a partial refund. Learn more
                                            </div>
                                        </div>

                                    </div>)}
                                <div className='mobile_btn_checkout'>
                                    <div className="yourtrip_wrapper_box_btn"
                                        onClick={() => createOrder(data.id, new_order_id)}>
                                        <button className="payment_confrim_btn">Confirm &amp; Pay</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >) : null
            }
        </>

    )
}

export default Checkout