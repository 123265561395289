import React, { useEffect, useState } from 'react'

const PropertyRequest = (props) => {
  
    return (
        <>
            <div className='property_details_main_wrapper'>
                <div className='container'>
                    <div className="property_details_form_wrapper">
                        <div className='form_title_proerty_details'>Property Details</div>
                        <form action="">
                            <div className='row'>
                                <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Property Type" class="form-label property_details_label">Category</label>
                                   
                                    <select class="form-select border_form_details" aria-label="Default select example">
                                        <option selected>Category</option>
                                        <option value="1">Category1</option>
                                        <option value="2">Category2</option>
                                        <option value="3">Category3</option>
                                    </select>
                                </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-1">
                                        <label for="Property Type" class="form-label property_details_label">Property Type</label>
                                        <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Bedrooms" class="form-label property_details_label">Bedrooms</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-1">
                                        <label for="Property Type" class="form-label property_details_label">Bathrooms</label>
                                        <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Property Type" class="form-label property_details_label">Square Footage</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-1">
                                        <label for="Property Type" class="form-label property_details_label">Parking (Number of Spaces)</label>
                                        <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Property Type" class="form-label property_details_label">Year Built</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-1">
                                        <label for="Property Type" class="form-label property_details_label">Lot Size</label>
                                        <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Property Type" class="form-label property_details_label">Price</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                                </div>
                               
                            </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Location</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Address Line 1</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Address Line 2</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">City</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">State</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Zipcode</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Description</label>
                                    <textarea type="text" rows="4" cols="4" className="form-control border_form_details" id="exampleInputPassword1"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='form_title_proerty_details mt-3'>Contact Informantion</div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Name</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Email</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div class="mb-1">
                                    <label for="Location" class="form-label property_details_label">Phone</label>
                                    <input type="text" className="form-control border_form_details" id="exampleInputPassword1"/>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'><button className='submit_btn_property_details'>Submit</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyRequest