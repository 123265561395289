import React, { useState } from 'react';
import "../Style.css";
import { Rating } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const PaymentHistory = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const common = useSelector((state) => state.common);
    const { showPrice } = props;

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <Link className="tripwrapper_row" to={`/user/orders/${props.data.id}`}>
                <div className="trip_left_bar">
                    <div className='trips_picture'>
                        {props.data.product.images[0].url ? (
                            <img src={props.data.product.images[0].url} alt="product" />
                        ) : (
                            <img src="https://previews.123rf.com/images/nadianb/nadianb1709/nadianb170900256/86443804-idyllic-landscape-in-mountains-rural-scene-beautiful-nature-image-toned-square.jpg" alt="logo" />
                        )}

                    </div>
                </div>
                <div className="trip_center_bar"><Link to={`/product/${props.data.product.id}`} className='cancellation_title_tripdetails'>
                    {props.data.product.name}
                </Link>

                    <div className='value_profile'>{props.data.product.description.split(' ').slice(0, 10).join(' ')}</div>
                    {/* <Rating className="trip_rating" name="half-rating-read" defaultValue={props.data.product.average_rating} precision={0.5} readOnly /> */}
                </div>
                <div className="trip_right_bar" style={{ textAlign: 'end' }}>
                    {showPrice ? (
                        <div className='ruppee_text'>{common.currency}{props.data.product.price}</div>
                    ) : (
                        <Link onClick={toggleDetails} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={showDetails ? faChevronDown : faChevronRight} className='trip_arrow_icon' />
                        </Link>
                    )}
                </div>
            </Link >
            {showDetails && (
                <div style={{
                    marginTop: '10px',
                    padding: '10px',
                    border: '1px solid #ddd',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '5px'
                }}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '10px',
                        fontSize: '14px',
                        lineHeight: '1.6'
                    }}>
                        <span style={{ fontWeight: 'bold' }}>Check In:</span>
                        <span>{props.data.check_in}</span>
                        <span style={{ fontWeight: 'bold' }}>Check Out:</span>
                        <span>{props.data.check_out}</span>
                        <span style={{ fontWeight: 'bold' }}>Total Amount:</span>
                        <span>{props.data.total_amount}</span>
                        <span style={{ fontWeight: 'bold' }}>Average Rating:</span>
                        <span>{props.data.product.average_rating}</span>
                        <span style={{ fontWeight: 'bold' }}>Discount:</span>
                        <span>{props.data.product.discount}</span>
                        <span style={{ fontWeight: 'bold' }}>ID:</span>
                        <span>{props.data.id}</span>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default PaymentHistory;
