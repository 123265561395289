import React, { useEffect, useState } from 'react'

const Check = (props) => {
    const [hide, setHide] = useState(props.collapse)
    const [checkedValues, setCheckedValues] = useState([]);

    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      props.getSelectedValue(value.toString())
      if (checked) {
        setCheckedValues((prevValues) => [...prevValues, value]);
      } else {
        setCheckedValues((prevValues) => prevValues.filter((val) => val !== value));
      }
    };

    useEffect(()=>{
        // props.getSelectedValue(checkedValues.toString())
    },[checkedValues])

    useEffect(()=>{
        setHide(props.collapse)
    },[props.collapse])

    useEffect(()=>{ 
        if(props.reset==1)
        {  
            setCheckedValues([]);
        }
    },[props.reset])

    return (
        <>
        {props.data.length > 0 && (
            <div className="panel panel-default">
                <div
                    className="panel-heading filter_title_header"
                    role="tab"
                    id="headingOne"
                    onClick={() => setHide(!hide)}
                >
                    <h4 className="panel-title">
                        <a
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            {props.title}
                        </a>
                    <span class="minuplus">{hide?'-':'+'}</span>
                    </h4>
                </div>
                <div
                    className={`collapse ${hide && 'show'}`}
                    id="collapseExample"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                >
                    <div className="panel-body">
                        {props.data.map((item,ind)=>(
                            <div className="form-group filter_checkbox_btn">
                                <input 
                                    type={'checkbox'} 
                                    name={props.id}
                                    id={props.id+ind} 
                                    value={item.id} 
                                    checked={checkedValues.includes(item.id.toString())}
                                    onChange={handleCheckboxChange}
                                    className='filter_check'
                                    />
                                
                                <label className="filture_check_box" htmlFor={props.id+ind}>{item.name}</label>
                            </div>
                        ))}
                        
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default Check