import React, { useState } from 'react'
import "../Style.css";
import { Rating } from '@mui/material';
import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
const Ratinglists = (props) => {
    function capitalizeFirstLetter(word = "hello") {

        if (word !== null) { return word.charAt(0).toUpperCase(); }
        else {
            return null;
        }
    }
    return (
        <>
            <div className="rental_product_rating">
                <div className="rental_product_rating_picture">
                    <Avatar src={props.data.image} sx={{ bgcolor: deepPurple[500], width:60, height:60 }}>{capitalizeFirstLetter(props.data.name)} </Avatar>
                </div>
                <div className="rental_product_tilte_rating">
                    <div className='productname_rating'>{props.data.name}</div>
                    <div className='productname_rating_dis'>{props.data.review.split(' ').slice(0, 10).join(' ')}</div>
                    {<Rating name="half-rating-read" defaultValue={props.data.rating} precision={0.5} size="small" readOnly />}                </div>
              
            </div>

        </>

    )
}

export default Ratinglists
