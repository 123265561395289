import React, { useState, useEffect } from 'react';
import Axios from '../../../Axios';
import { Link, useNavigate } from 'react-router-dom';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import axios from 'axios';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import '../../../Style.css';

const Profile = () => {
  const [editedValues, setEditedValues] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [image, setImage] = useState(localStorage.getItem('image'));
  const dispatch = useDispatch();
  const [isEdit1, setEdit1] = useState(false);
  const [isPhoneValidState, setIsPhoneValidState] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [phone, setPhone] = useState('')
  const phoneUtil = PhoneNumberUtil.getInstance();
  const navigate = useNavigate();
  const isPhoneValid = (phone) => {
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  };

  const fetchData = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get(`/user`);
      if (resp.data.success && resp.data.code === 'EC200') {
        setEditedValues(resp.data.data.value);
        setValues(resp.data.data.value);
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      dispatch(updateLoader({ loader: false }));
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    checkEmailVerification();
  }, []);

  useEffect(() => {
    localStorage.setItem('image', values.image);
    const event = new CustomEvent('imageUpdated', { detail: values.image });
    window.dispatchEvent(event);
  }, [values.image]);

  const handleEditChangeNumber = (value, s) => {
    const country_short_code = s.country.iso2.toUpperCase();
    const dialCode = s.country?.dialCode || '';
    let rawInputValue = (s.inputValue || '').toString(); // Ensure inputValue is a string

    // Extract numbers from rawInputValue
    rawInputValue = rawInputValue.match(/\d+/g);
    rawInputValue = rawInputValue ? rawInputValue.join('') : '';

    const newValues = {
      ...values,
      'phone': value,
      'dialCode': dialCode,
      'rawInputValue': rawInputValue,
      'country_short_code': country_short_code,
    };

    setValues(newValues);

    // Validate phone number and determine save button status
    const isValid = isPhoneValid(value);
    setIsPhoneValidState(isValid);
    setErrors({ ...errors, phone: isValid ? '' : 'Invalid phone number' });

    // Check if the new phone value differs from the original edited value
    const isPhoneChanged = value !== editedValues.phone;

    // Disable save if phone is invalid or if phone hasn't changed
    const disableSave = !isValid || !isPhoneChanged;
    setIsSaveDisabled(!disableSave);
  };

  const handleEditChange = (value, field) => {
    if (field != "phone") {
      const newValues = { ...values, [field]: value };
      setValues(newValues);
    }


    setIsEditing(true);

    let disableSave = true;

    if (field === 'phone') {
      const isValid = isPhoneValid(value);
      setIsPhoneValidState(isValid);
      setErrors({ ...errors, phone: isValid ? '' : 'Invalid phone number' });

      if (value == editedValues.phone) {
        disableSave = true;
      } else if (!isValid) {
        disableSave = true;
      } else {
        disableSave = false;
      }
    }

    if ((field === 'name' && value.length < 1) || (field === 'email' && value.length < 1)) {
      disableSave = true;
    }

    if ((field === 'name' && value !== editedValues.name) || (field === 'email' && value !== editedValues.email)) {
      disableSave = false;
    }

    setIsSaveDisabled(disableSave);
    setEdit1(true);
  };

  const handleSave = async () => {
    console.log(values, "values");
    if (!isPhoneValid(values.phone)) {
      setErrors({ ...errors, phone: 'Invalid phone number' });
      setIsPhoneValidState(false);
      setIsSaveDisabled(true);
      return;
    } else {
      setIsPhoneValidState(true);
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.rawInputValue);
    formData.append('country_code', values.dialCode)
    formData.append('country_short_code', values.country_short_code)


    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.post('/user', formData);
      console.log(resp);
      setIsEditing(false);
      fetchData();
      setErrors({});
      localStorage.setItem('name', values.name);
      localStorage.setItem('phone', values.phone);
      dispatch(updateLoader({ loader: false }));
      setIsSaveDisabled(true);
      toast.success(`${resp.data.data.message}`)
      navigate('/')
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message);
        setErrors({ ...errors, form: error.response.data.message });
      } else {
        console.log('error ', error.response.data.data.Validation_Error);
        setErrors({ ...errors, form: error.response.data.data.Validation_Error });
      }
      dispatch(updateLoader({ loader: false }));
      setIsSaveDisabled(true);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > 1024 * 1024) {
      console.error('The image must be less than 1MB in size.');
      toast.error('The image must be less than 1MB in size.');
      return;
    }

    uploadImage(selectedFile);
    const fileName = selectedFile.name;

    if (selectedFile && isValidImageType(fileName)) {
    } else {
      console.error('The image must be a file of type: jpeg, png, jpg, gif, webp.');
    }
  };

  const isValidImageType = (fileName) => {
    const validExtensions = ['jpeg', 'jpg', 'png', 'gif', 'webp'];
    const extension = fileName.split('.').pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  const uploadImage = async (e) => {
    try {
      const formData = new FormData();
      formData.append('image', e);
      console.log(formData);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/profile-update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log(response);
      if (response.data.code === 'EC200') {
        setValues({ ...values, image: response.data.imageUrl });
        localStorage.setItem('image', values.image);
        toast.success('Image uploaded successfully');
        fetchData();
      } else {
        console.error('Failed to upload image', response.data);
        toast.error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
    }
  };

  const handleEmail = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.post('/send-email-verification', {
        email: values.email,
      });
      console.log(resp.data.data.testing_url);
      toast.success('Email sent successfully');
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      console.error('Error sending verification email:', error.response.data);
      toast.error(error.response.data.data.message);
      dispatch(updateLoader({ loader: false }));
    }
  };

  const checkEmailVerification = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get('/check-email-verification');
      setIsEmailVerified(resp.data.data.status);
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      console.error('Error checking email verification:', error);
      dispatch(updateLoader({ loader: false }));
    }
  };

  const handleCancel = () => {
    setValues(editedValues);
    setIsEditing(false);
    setErrors({});
    setIsSaveDisabled(true);
  };

  return (
    <div className="profile_section">
      <div className="profile_wrapper">
        <div className="container">
          <Link to={'/'} style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
            <div className="backarrowbtn">
              <i className="fa fa-long-arrow-left"></i>
            </div>
            <div className="profilebackbtn">Profile</div>
          </Link>
          <div className="card1 profile_section_wrapper">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span onClick={() => (isEditing ? handleCancel() : setIsEditing(true))}>
                {isEditing ? (
                  <span className="profileeditepencile"><i className="fa fa-close"></i></span>
                ) : (
                  <span className="profileeditepencile"><i className="fa fa-pencil"></i></span>
                )}
              </span>
            </div>
            <div className="avatar-upload_profile_rental">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg, .gif, .webp"
                  onChange={handleFileChange}
                />
                <label htmlFor="imageUpload">
                  <i className="fa fa-camera"></i>
                </label>
              </div>
              <div className="avatar-preview">
                {values.image ? (
                  <Avatar src={values.image} sx={{ bgcolor: deepPurple[500] }} style={{ width: '100%', height: '100%' }} />
                ) : (
                  <Avatar sx={{ bgcolor: deepPurple[500] }} style={{ width: '100%', height: '100%' }}></Avatar>
                )}
              </div>
            </div>
            <div className="card-row justify-content-between">
              <div className="profile_page_left">
                <div className="title_profile">Name</div>
                <div className="value_profile">
                  <div className="profile_edit_form_input">
                    {isEditing ? (
                      <input
                        className="form-control"
                        type="text"
                        value={values.name || ''}
                        maxLength={20}
                        onChange={(e) => handleEditChange(e.target.value, 'name')}
                      />
                    ) : (
                      editedValues?.name || ''
                    )}
                  </div>
                </div>
                {errors.name && <div className="error-message">{errors.name}</div>}
              </div>
            </div>
            <hr className="border-bottom_profile" />

            <div className="card-row justify-content-between">
              <div className="profile_page_left">
                <div className="title_profile">Email</div>
                <div className="value_profile">
                  <div className="profile_edit_form_input">
                    {isEditing && !isEmailVerified ? (
                      <input
                        className="form-control"
                        type="email"
                        value={values.email || ''}
                        onChange={(e) => handleEditChange(e.target.value, 'email')}
                      />
                    ) : (
                      editedValues?.email || ''
                    )}
                    {!isEmailVerified && isEditing && (
                      <button className="btn profile_save_btn" onClick={handleEmail}>Verify</button>
                    )}
                  </div>
                </div>
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
            </div>
            <hr className="border-bottom_profile" />

            <div className="card-row justify-content-between">
              <div className="profile_page_left">
                <div className="title_profile">Phone</div>
                <div className="value_profile">
                  <div className="profile_edit_form_input">
                    {isEditing ? (
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={`${values?.phone}`}
                        onChange={(value, s) => {
                          handleEditChangeNumber(value, s);
                          handleEditChange(value, 'phone')
                        }}
                        defaultCountry="fr"
                      />


                    ) : (
                      <>+{values?.phone}</>
                    )}
                  </div>
                </div>
                {errors.phone && <span className="error-message">{errors.phone}</span>}
              </div>
            </div>
            <div className="card-row justify-content-end" style={{ padding: '10px 0' }}>
              {isEditing && (
                <button
                  type="button"
                  className={"profile_save_btn"}
                  onClick={handleSave}
                  disabled={isSaveDisabled}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
