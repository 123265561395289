import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import Input from '../../Components/Input';
import axios from 'axios';
import { updateCommons } from '../../Redux/commonSlice';
import { useDispatch } from 'react-redux';
import { updateLoader } from '../../Redux/loaderSlice';
import { toast } from 'react-toastify';
import "../../Style.css";
const Forgetpassword = () => {
    const [passwordshow, setPasswordShow] = useState(false);
    const handlePasswordClose = () => { setPasswordShow(false); navigate('/') };
    const handlePasswordShow = () => setPasswordShow(true);
    let { token } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // alert(token);
        handlePasswordShow()
    }, [token])
    const handleLoginModal = () => {
        dispatch(updateCommons({ loginform: true })); // Dispatch action to update loginform state in Redux
    };


    const initialState = {
        password: "",
        confirm_password: "",
    };
    const initialErrorState = {
        passwordError: "",
        confirm_passwordError: "",
    };
    const [formData, setFormData] = useState(initialState);
    const [errorData, setErrorData] = useState(initialErrorState);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormReset = () => {
        setFormData(initialState); // Reset form data to initial state
    };

    const handleErrorReset = () => {
        setErrorData(initialErrorState); // Reset form data to initial state
    };

    const handleForgot = async (e) => {
        try {
            handleErrorReset();
            if (e.password.length < 8) {
                setErrorData(prevState => ({ ...prevState, passwordError: "Please enter password minimum 8 char" }));
            } else if (e.confirm_password.length < 8) {
                setErrorData(prevState => ({ ...prevState, confirm_passwordError: "Please enter confirm password same as password" }));
            } else {
                dispatch(updateLoader({ loader: true }));
                const resp = await axios.put(process.env.REACT_APP_API_URL + "/update-password", e, {
                    headers: {
                        "Encrypt-Email": token
                    }
                });
                console.log(resp)
                if (resp.data.code == 'EC200') {
                    handleFormReset()
                    handlePasswordClose();
                    toast.success(resp.data.data.message)

                    navigate('/')
                    dispatch(updateCommons({ loginshow: true }));
                }

            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            console.error('Error:', error);
            console.error('Status code:', error.response.status);
            if (error.response.status === 404) {

            } else if (error.response.status === 400) {
                Object.keys(error.response.data.data.Validation_Error).map(field => (
                    setErrorData(prevState => ({ ...prevState, [field + 'Error']: error.response.data.data.Validation_Error[field] }))
                ))
            } else if (error.response.status === 401) {
                setErrorData(prevState => ({ ...prevState, confirm_passwordError: "Token expired" }))
            } else if (error.response.status === 500) {
                // Handle 500 error
            } else {
                // Handle other errors
            }
        }
    };

    return (
        <>
            <Modal show={handleLoginModal} onHide={handlePasswordClose}>
                <Modal.Body>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="">
                            <div className="AppForm rental_property_login_form_wrapper">
                                <div className="AppFormLeft">
                                    <div className="rentel_login_logo">
                                        <img src="/images/logo.png" className="img-fluid" alt="logo" />
                                    </div>
                                    <div className="rentel_login_header">
                                        <h1>Reset Password</h1>
                                    </div>

                                    <Input
                                        title={'Password'}
                                        type={'password'}
                                        name={'password'}
                                        icon={'/icon/Group 20.svg'}
                                        placeholder={'Please enter Password'}
                                        value={formData.password}
                                        onChange={handleChange}
                                        error={errorData.passwordError} />

                                    <Input
                                        title={'Confirm Password'}
                                        type={'password'}
                                        name={'confirm_password'}
                                        icon={'/icon/Group 20.svg'}
                                        placeholder={'Please enter Password'}
                                        value={formData.confirm_password}
                                        onChange={handleChange}
                                        error={errorData.confirm_passwordError} />


                                    <button className="button" style={(formData.password === '' || formData.confirm_password === '')
                                        ? styles.disabledButton : styles.enabledButton}
                                        disabled={(formData.password === '' || formData.confirm_password === '')} onClick={() => handleForgot(formData)}>Reset password</button>


                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div style={{ height: 300 }}>


            </div>
        </>
    )
}

export default Forgetpassword
const styles = {
    disabledButton: {
        backgroundColor: '#D8D8D8',
        color: 'white',
        cursor: 'not-allowed',

    },
    enabledButton: {
        background: 'rgba(110, 127, 214, 1)',
        color: 'white',
        cursor: 'pointer',
    },

};