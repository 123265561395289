import { useDispatch, useSelector } from 'react-redux';
import Axios from '../Axios';
import React, { useEffect, useState } from 'react'
import { addCategory } from '../Redux/categorySlice';
import { addCountry } from '../Redux/countrySlice';
import { addStatic } from '../Redux/staticSlice';
import GetStaticValue from '../utility/GetStaticValue';
import { updateLoader } from '../Redux/loaderSlice';
import { Link } from 'react-router-dom'


const Footer = () => {
  const [footer, setFooter] = useState([]);
  const dispatch = useDispatch();
  const staticData = useSelector(state => state.staticData);

  const getFooter = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get('/footer')

      if (resp.data.code == "EC200") {
        setFooter(resp.data.data.data)
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
      dispatch(updateLoader({ loader: false }));
    }
  }

  const getCategory = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get('/categories')
      if (resp.data.code == "EC200") {
        dispatch(addCategory(resp.data.data.data))
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
      dispatch(updateLoader({ loader: false }));
    }
  }

  const getCountry = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get('/country')
      if (resp.data.code == "EC200") {
        dispatch(addCountry(resp.data.data.data))
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
      dispatch(updateLoader({ loader: false }));
    }
  }

  const getStatic = async () => {
    try {
      dispatch(updateLoader({ loader: true }));
      const resp = await Axios.get('/static')
      if (resp.data.code == "EC200") {
        dispatch(addStatic(resp.data.data.transalte))
      }
      dispatch(updateLoader({ loader: false }));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(error.response)
      } else {
        console.log(error.message);
      }
      dispatch(updateLoader({ loader: false }));
    }
  }

  useEffect(() => {
    getFooter()
    getCategory();
    getCountry();
    getStatic();
  }, [])



  return (
    <>
<div className='footer_main_wrapper'>
      <div className="footer">
        <div className="container">
          <div className="row">
            {footer.map((item, ind) => (
              <div className={`col-md-${ind == 0 || ind == 3 ? 3 : 2}`} key={ind}>
                <div className="single_footer">
                  <div className="footer_hedding_title">
                    <h4>{item.name}</h4>
                  </div>
                  <ul className="footer_menu_list">
                    {item.children.map((innerItem, innerInd) => (
                      <li key={innerInd}>
                        <Link to={innerItem.url} >{innerItem.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}

            {/*- END COL */}
            <div className="col-md-2">
              <div className="single_footer single_footer_address">
                <div className="footer_hedding_title">
                  <h4>Get the app</h4>
                </div>
                <div className="footer_downlode_img">
                  <div className="footer_apss_picture">
                    <a href={GetStaticValue('app_url_google_play')} rel="noopener noreferrer" target="_blank">
                      <img src="/images/playstore.png" alt="playstoreimg" className="img-fluid" />
                    </a>
                  </div>
                  <div className="footer_apss_picture">
                    <a href={GetStaticValue('app_url_app_store')} rel="noopener noreferrer" target="_blank">
                      <img src="/images/apple.png" alt="appleimg" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*- END COL */}
          </div>
          {/*- END ROW */}
        </div>
        {/*- END CONTAINER */}
      </div>


      <div className="copyright_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="conpyright_text text-capitalize">
                {GetStaticValue('copyright')} 
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="fotter_copyright_icone">
                <div className='social_icon' >
                  <a href={GetStaticValue('facebook')} rel="noopener noreferrer" target="_blank">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </div>
                <div className='social_icon'>
                  <a href={GetStaticValue('X')} rel="noopener noreferrer" target="_blank">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </div>
                <div className='social_icon'>
                  <a href={GetStaticValue('instagram')} rel="noopener noreferrer" target="_blank">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>



    </>
  )
}

export default Footer