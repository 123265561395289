import { useState } from 'react'; // Import useState
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateLoader } from "../../Redux/loaderSlice";
import { updateCommons } from "../../Redux/commonSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

export default function EmailReset() {
    const dispatch = useDispatch();
    const [handleLoginModal, setHandleLoginModal] = useState(true); // State for controlling modal visibility
    const navigate = useNavigate();
    let { token } = useParams();

    useEffect(() => {
        const handleEmail = async (e) => {
            try {
                dispatch(updateLoader({ loader: true }));
                const resp = await axios.put(process.env.REACT_APP_API_URL + "/email-verification", e, {
                    headers: {
                        "Encrypt-Email": token
                    }
                });
                console.log(resp)
                if (resp.data.code === 'EC200') {
                    dispatch(updateLoader({ loader: true }));

                    navigate('/');

                }
                dispatch(updateLoader({ loader: false }));
            } catch (error) {
                dispatch(updateLoader({ loader: false }));
                console.error('Error:', error);
                console.error('Status code:', error.response.status);
            }
        };

        handleEmail({});
    }, [dispatch, navigate, token]); // Add dependencies

    const closeModal = () => {
        setHandleLoginModal(false); // Close modal when called
    };

    return (
        <>
            <Modal show={handleLoginModal} onHide={closeModal}> {/* Add onHide event */}
                <Modal.Body>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="">
                            <div className="AppForm rental_property_login_form_wrapper">
                                <div className="AppFormLeft">
                                    <div className="rentel_login_header">
                                        <h1>Your email has been verified</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div style={{ height: 300 }}></div>
        </>
    );
}
