import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../../Axios';
import { updateLoader } from '../../../Redux/loaderSlice';
import { updateCommons } from '../../../Redux/commonSlice';

const Wishlists = () => {
    const [favorite, setFavorite] = useState([]);
    const dispatch = useDispatch();
    const common = useSelector((state) => state.common);
    const navigate = useNavigate();

    const getData = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/favorite');
            if (resp.data.code === "EC200") {
                setFavorite(resp.data.data.data);
                console.log(resp.data.data.data);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
            setFavorite([]);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleWishlistUpdate = () => {
        getData();
    };

    const updateWishlist = async (propertyId) => {
        try {
            const article = {
                property_id: propertyId
            };
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.post('/favorite', article);
            if (resp.data.code === "EC200") {
                const status = resp.data.data.status;
                setFavorite((prevFavorites) =>
                    prevFavorites.map((item) =>
                        item.id === propertyId ? { ...item, in_wishlist: status } : item
                    )
                );
                handleWishlistUpdate();
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else if (error.response && error.response.status === 401) {
                dispatch(updateCommons({ loginshow: true }));
            } else {
                console.log(error.response);
            }
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleClick = (e) => {
        const state = {
            id: e.id,
            name: e.name,
            price: e.price,
            rating: e.rating,
            image: e.images[0]?.image
        };
        navigate('/user/checkout', { state });
    };

    return (
        <>
            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className="backarrowbtn"><i className="fa fa-long-arrow-left"></i></div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>Wishlists</div>
                        </Link>
                        <div className='container'>
                            <div className='card1 profile_section_wrapper'>
                                <div className='row'>
                                    {
                                        favorite.length > 0 ? (
                                            favorite.map((item, ind) => (
                                                <div key={ind} className={`col-md-6 col-sm-6 col-lg-4 col-12`} >
                                                    <div className="card_wrapper_box">
                                                        <div className="images_box">
                                                            <Link to={`/product/${item.id}`} className="nav-link" onClick={scrollToTop}>
                                                                <img src={item?.images[0]?.image} className="img-fluid" alt="logo" style={{ height: 200, width: 300 }} />
                                                            </Link>
                                                        </div>

                                                        <div className="property_icon_heart" onClick={() => updateWishlist(item.id)}>
                                                            {item.in_wishlist ? (
                                                                <i className={`fa ${item.in_wishlist ? 'fa-heart' : 'fa-heart-o'}`} style={{ color: 'red' }}></i>
                                                            ) : (
                                                                <i className='fa fa-heart'></i>
                                                            )}
                                                        </div>
                                                        <div className="text_wrapper_box">
                                                            <div className="text_inner_wrapper_box">
                                                                <div className="propery_left">
                                                                    <div className="property_title_name">
                                                                        <h4>{item.country}, {item.city}</h4>
                                                                    </div>
                                                                    <div className="property_title_subname text_in_one_line_property_name">{item.name}</div>
                                                                </div>

                                                                {item.rating !== 0 && (
                                                                    <div className="card_box_riben">
                                                                        <i className="fa fa-star" /> {item.rating}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="cardbtn_wrpper" >
                                                                <div className="card_btn_inner_wrapper">
                                                                    <div className="ruppee_text">
                                                                        {common.currency} {item.payablePrice}
                                                                    </div>
                                                                    <div className="htro_price">
                                                                        {common.currency}
                                                                        {item.price}
                                                                    </div>

                                                                    <div className="booknow_btn d-none">
                                                                        <Link to={`/product/${item.id}`} className="btn property_book_btn" onClick={scrollToTop}>
                                                                            View Property
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="d-flex justify-content-center no-data_dummy-text">Wishlist is Empty</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wishlists;
