import React, { useEffect } from 'react'
import Pusher from 'pusher-js';
import { Link } from 'react-router-dom';
import Axios from '../../../Axios';
import SingleRow from '../../../Components/SingleRow';
import { useDispatch, useSelector } from 'react-redux';
import { addNoti } from '../../../Redux/notificationSlice';
import { updateLoader } from '../../../Redux/loaderSlice';

const Notification = () => {
    const dispatch = useDispatch();

    const [notification, setNotification] = React.useState([]);
    const authenticatedid = useSelector((state) => state.user.id);

    const fetchData = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get(`/notification`);
            if (resp.data.success && resp.data.code === "EC200") {
                setNotification(resp.data.data.data);
                removeData();
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            console.error('Error fetching user data:', error);
            removeData();
        }
    };

    const removeData = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.post(`/remove-notification`);
            if (resp.data.success && resp.data.code === "EC200") {
                dispatch(updateLoader({ loader: true }));
                dispatch(addNoti(0))
                dispatch(updateLoader({ loader: false }));
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            dispatch(updateLoader({ loader: true }));
            console.error('Error fetching user data:', error);
            dispatch(updateLoader({ loader: false }));
        }
    };

    useEffect(() => {
        const pusher = new Pusher('f421f16e68d5f31688d1', {
            cluster: 'ap2',
            encrypted: true
        });

        const channel = pusher.subscribe('user_channel_' + authenticatedid);
        channel.bind('notification', (data) => {
            fetchData();
        });

        fetchData();
        return () => {
            pusher.disconnect();
        };
    }, [authenticatedid]);



    return (
        <>
            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to={'/'} style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className="backarrowbtn"><i className="fa fa-long-arrow-left"></i></div>
                            <div className="profilebackbtn">Notifications
                                {/* notification */}
                            </div>
                        </Link>
                        <div className="card1 profile_section_wrapper">
                            {notification.length === 0 ? (
                                <div className='no-data_dummy-text'>No Notification</div>
                            ) : (
                                notification.map((item, ind) => (
                                    <SingleRow item={item} key={ind} hr />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification;