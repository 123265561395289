import { createSlice } from "@reduxjs/toolkit";
import { getTodayDate } from "../utility";

const initialState = {
    currency: '€',
    loginshow: false,
}

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        updateCommons: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})
export default commonSlice.reducer;
export const { updateCommons } = commonSlice.actions;

