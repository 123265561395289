import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from '../Axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateCommons } from '../Redux/commonSlice';
import { updateLoader } from '../Redux/loaderSlice';

const ProductCard = ({ data, oneRow, onWishlistUpdate }) => {
    const navigate = useNavigate();
    const common = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [wishlist, setWishlist] = useState(data.in_wishlist);

    const updateWishlist = async (propertyId) => {
        try {
            const article = {
                property_id: propertyId
            };
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.post('/favorite', article);
            if (resp.data.code === "EC200") {
                const status = resp.data.data.status;
                setWishlist(status);
                onWishlistUpdate(); // Send data to parent
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else if (error.response && error.response.status === 401) {
                dispatch(updateCommons({ loginshow: true }));
            } else {
                console.log(error.response);
            }
        }
    };


    const handleClick = (e) => {
        const state = {
            id: e.id,
            name: e.name,
            price: e.price,
            rating: e.rating,
            image: e.images[0]?.image
        };
        navigate('/user/checkout', { state });
    };

    return (
        <div className={`col-md-${oneRow ? 6 : 4} col-sm-6 col-lg-${oneRow ? oneRow : 3} col-12`} >
            <div className="card_wrapper_box">
                <div className="images_box">
                    <Link to={`/product/${data.id}`} className="nav-link" onClick={scrollToTop}>
                        <img src={data?.images[0]?.image} className="img-fluid" alt="logo" style={{ height: 200, width: 300 }} />
                    </Link>
                </div>

                <div className="property_icon_heart" onClick={() => updateWishlist(data.id)}>
                    {wishlist ? (
                        <i className={`fa ${wishlist ? 'fa-heart' : 'fa-heart-o'}`} style={{ color: 'red' }}></i>
                    ) : ( 
                        <i className='fa fa-heart'></i>
                    )}

                </div>
                <div className="text_wrapper_box">
                    <div className="text_inner_wrapper_box">
                        <div className="propery_left">
                            <div className="property_title_name">
                                <h4>{data.country}, {data.city}</h4>
                            </div>
                            <div className="property_title_subname text_in_one_line_property_name">{data.name}</div>
                        </div>

                        {data.rating !== 0 && (
                            <div className="card_box_riben">
                                <i className="fa fa-star" /> {data.rating}
                            </div>
                        )}

                    </div>
                    <div className="cardbtn_wrpper" >
                        <div className="card_btn_inner_wrapper">
                            <div className="ruppee_text">
                                {common.currency} {data.payablePrice}
                            </div>
                            <div className="htro_price">
                                {common.currency}
                                {data.price}
                            </div>

                            <div className="booknow_btn d-none">
                                <Link to={`/product/${data.id}`} className="btn property_book_btn" onClick={scrollToTop}>
                                    View Property
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
