import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { updateLoader } from './Redux/loaderSlice';
import { setGoogleUser, setUser } from './Redux/authSlice';
import { Avatar } from '@mui/material';
import Footer from '../src/Common/Footer';
function GoogleCallback() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(localStorage.getItem('image'));



    useEffect(() => {
        dispatch(updateLoader({ loader: true }));
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/callback${location.search}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                dispatch(setUser(data.data));
                localStorage.setItem("token", data.data.token);
                localStorage.setItem("id", data.data.id);
                localStorage.setItem("name", data.data.name);
                localStorage.setItem("image", data.data.image);
                localStorage.setItem("phone", data.data.phone);

                setImage(data.data.image); // Update the image state
                const event = new CustomEvent('imageUpdated', { detail: data.data.image });
                window.dispatchEvent(event);
                setLoading(false); // Set loading to false
                dispatch(updateLoader({ loader: false }));
                navigate('/');
            })
            .catch(error => {
                console.error('Error during the Google authentication callback:', error);
                dispatch(updateLoader({ loader: false }));
                setLoading(false); // Set loading to false on error
            });
    }, [dispatch, location.search, navigate]);

    if (loading) {
        return
        <>
            <DisplayLoading />;
            <Footer />
        </>
    }

    return (
        <div>
            <Avatar src={image} className='afterlogin_avtore' />
        </div>
    );
}

function DisplayLoading() {
    return <div></div>;
}

export default GoogleCallback;
