import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from '../../Axios';
import { toast } from 'react-toastify';
import { updateLoader } from '../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';

const Help = () => {

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        type: '',
        message: ''
    });

    const handleOptionChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            type: value
        });
    };

    const handleMessageChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            message: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch(updateLoader({ loader: true }));
            const response = await Axios.post('/help', formData);
            if (response.data.code === "EC200") {
                toast.success(`${response.data.data.message}`);
            }

            console.log(response.data, 'this is the property request response');
            dispatch(updateLoader({ loader: false }));
        } catch (error) {

            console.error('Error submitting property data:', error);
            dispatch(updateLoader({ loader: false }));
        }
    };

    return (
        <>
            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className='backarrowbtn'>
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className="profilebackbtn">Help and feedback</div>

                        </Link>

                        <div className='card1 profile_section_wrapper'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label>Help type</label>
                                    <select className='form-control' value={formData.type} onChange={handleOptionChange}>
                                        <option value="" disabled>Please select help type</option>
                                        <option value="option1">How to use IMURAS AGRAW  : Cf : Texte</option>
                                        <option value="option2">Trip cancellation by the traveler</option>
                                        <option value="option3">Trip concellation by the Vendor</option>
                                        <option value="option3">Refund following trip cancellation</option>
                                        <option value="option3">Payment</option>
                                        <option value="option3">Contact : bye email, phone number</option>
                                    </select>
                                    {errors.option && <p className='error'>{errors.option}</p>}
                                </div>

                                <div className='form-group'>
                                    <label>Your message</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleMessageChange}
                                        placeholder='Please enter your message'
                                    />
                                    {errors.message && <p className='error'>{errors.message}</p>}
                                </div>

                                <button type="submit" className='help_savebtn' disabled={!formData.message || !formData.type}>Save</button>
                            </form>

                            <div className='helpcontactnumber'>
                                <Link to='/privacypolicy'>Claim</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Help;
