import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Axios from '../Axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateLoader } from '../Redux/loaderSlice';
import { toast } from 'react-toastify';
import "../Style.css";
import { logoutUser } from '../Redux/authSlice';
const Loginpassword = ({ title, description, todo }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showUpdateText, setShowUpdateText] = useState(true);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [oldPasswordValid, setOldPasswordValid] = useState(false);
    const navigate = useNavigate();
    const ini = {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
    }
    const [formData, setFormData] = useState(ini);
    const [errorData, setErrorData] = useState({
        old_passwordError: "",
        passwordError: "",
        confirm_passwordError: ""
    });

    const [todoColor] = useState(todo.toLowerCase() === 'update' ? '' : 'red');

    const handleClose = () => {
        setShowModal(false);
    };
    useEffect(() => {
        // Add your API call to check old password here
        const checkOldPassword = async () => {
            try {
                const resp = await Axios.get('/check-old-password');
                console.log(resp.data.data.status);
                setOldPasswordValid(resp.data.data.status);
                // Handle response as needed
            } catch (error) {
                console.error('Error checking old password:', error);
                // Handle error if needed
            }
        };

        checkOldPassword();
    }, []);
    const handleYesButtonClick = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.delete('/user-delete');
            console.log(resp);
            if (resp.data.code === "EC200") {
                await dispatch(logoutUser());
                localStorage.clear();
                localStorage.removeItem('name');
                localStorage.removeItem('token');
                localStorage.removeItem('image');
                setShowModal(false);
                navigate('/');
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            console.error('Error logging out:', error);
            // Handle error if needed
            dispatch(updateLoader({ loader: false }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {
        const requestData = {
            key: "old_password",
            old_password: formData.old_password,
            password: formData.new_password,
            confirm_password: formData.new_password_confirmation,

        };

        // // Add new_password field to requestData if old_password is provided
        // if (formData.old_password) {
        //     requestData.new_password = formData.new_password;
        //     requestData.new_password_confirmation = formData.new_password_confirmation;
        // }
        // else {
        //     requestData.new_password = formData.new_password;
        //     requestData.new_password_confirmation = formData.new_password_confirmation;
        // }
        setErrorData({
            old_passwordError: "",
            passwordError: "",
            confirm_passwordError: ""
        });
        if (formData.new_password !== formData.new_password_confirmation) {
            setErrorData(prevState => ({
                ...prevState,
                passwordError: "Passwords do not match",
                confirm_passwordError: "Passwords do not match"
            }));
            return; // Exit early if passwords don't match
        }

        // Check if any password field is less than 8 characters
        if (formData.old_password.length < 8) {
            setErrorData(prevState => ({
                ...prevState,
                old_passwordError: "Password must be at least 8 characters long"
            }));
        }

        if (formData.new_password.length < 8) {
            setErrorData(prevState => ({
                ...prevState,
                passwordError: "Password must be at least 8 characters long"
            }));
        }

        if (formData.new_password_confirmation.length < 8) {
            setErrorData(prevState => ({
                ...prevState,
                confirm_passwordError: "Password must be at least 8 characters long"
            }));
        }
        if (!formData.old_password) {
            setErrorData(prevState => ({
                ...prevState,
                old_passwordError: "Please enter password"
            }));
        }

        if (!formData.new_password) {
            setErrorData(prevState => ({
                ...prevState,
                passwordError: "Please enter password"
            }));
        }

        if (!formData.new_password_confirmation) {
            setErrorData(prevState => ({
                ...prevState,
                confirm_passwordError: "Please enter password"
            }));
        }



        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.post('/change-password', requestData);
            console.log(resp);
            dispatch(updateLoader({ loader: false }));
            setFormData(ini)
            toast.success('Password Update successfully')
            navigate('/')

        } catch (error) {

            dispatch(updateLoader({ loader: false }));
            console.error('Error occurred:', error);
            if (error.response.data.code === "EC400") {
                Object.keys(error.response.data.data.Validation_Error).map(field => (
                    setErrorData(prevState => ({ ...prevState, [field + 'Error']: error.response.data.data.Validation_Error[field] }))
                ))

            } else if (error.response.data.code == "EC400") {
                // setErrorData(prevState => ({ ...prevState, ['passwordError']: error.response.data.data.Validation_Error.new_password[0] }))      // alert(error.response.data)
            } else {
                // Handle other errors
            }
        }
    };

    const handleCancelButtonClick = () => {
        setShowForm(false);
        setShowUpdateText(true);
        setShowCancelButton(false);
    }

    const handleTodoClick = () => {
        if (todo.toLowerCase() === 'update') {
            setShowForm(true);
            setShowUpdateText(false);
            setShowCancelButton(true);
        } else if (todo.toLowerCase() === 'delete') {
            setShowModal(true);
        }
    };

    return (
        <>
            <div className="card-row justify-content-between" style={{ marginBottom: '0px' }}>
                <div className={showUpdateText ? "col-8" : "col-12"}>
                    <div className="title_profile">{title}</div>
                    <div className='value_profile'>{description}</div>
                    {showForm && (
                        <div className='security_form_inner_wrapper'>
                            <div className="form-group">
                                <label htmlFor="oldPassword">Old Password</label>
                                <input
                                    type="password"
                                    className="form-control security_form"
                                    id="oldPassword"
                                    name="old_password"
                                    value={formData.old_password}
                                    onChange={handleChange}
                                    disabled={!oldPasswordValid}
                                />
                                {errorData.old_passwordError && <p className="error_input">{errorData.old_passwordError}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword">New Password</label>
                                <input
                                    type="password"
                                    className="form-control security_form"
                                    id="newPassword"
                                    name="new_password"
                                    value={formData.new_password}
                                    onChange={handleChange}
                                    disabled={!oldPasswordValid}
                                />
                                {errorData.passwordError && <p className="error_input">{errorData.passwordError}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    className="form-control security_form"
                                    id="confirmPassword"
                                    name="new_password_confirmation"
                                    value={formData.new_password_confirmation}
                                    onChange={handleChange}
                                    disabled={!oldPasswordValid}
                                />{!oldPasswordValid && (
                                    <p>Please use "Forgot Password" instead.</p>
                                )}
                                {errorData.confirm_passwordError && <p className="error_input">{errorData.confirm_passwordError}</p>}
                            </div>
                            <button type="submit" style={{ marginRight: '2%' }} onClick={handleSave} disabled={!oldPasswordValid || !formData.old_password || !formData.new_password || !formData.new_password_confirmation} className=" btn_secourty">Submit</button>
                            {showCancelButton && (
                                <button type="button" className="btn btn_secourty_cancel ml-2" onClick={handleCancelButtonClick} disabled={!oldPasswordValid}>Cancel</button>
                            )}
                        </div>
                    )}
                </div>
                {showUpdateText && (
                    <div className="col-2" style={{ textAlign: 'end' }}>
                        <div className={`security_login_updatebtn ${todo == 'Delete' && 'text_delete_color'}`} style={{ cursor: 'pointer', color: todo == 'Delete' ? 'red; !important' : 'blue' }} onClick={handleTodoClick} >{todo}</div>
                    </div>
                )}
            </div>
            {/* Modal */}
            <Modal show={showModal} onHide={handleClose} className='confrim-deletion'>
                <Modal.Header closeButton style={{ border: '0px' }}>
                    {/* <Modal.Title>Confirm Deletion</Modal.Title> */}
                </Modal.Header >
                <Modal.Body >
                    <div className='confrim_title_hedding'>Confirm Deletion ?</div>
                    <div className='body_text_title'>Are you sure you want to delete this account?</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center pb-5' style={{ border: '0px', }}>
                    <Button className='btn_confrim_deletion' variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className='btn_confrim_deletion' variant="primary" style={{ color: 'white', backgroundColor: 'red', borderColor: 'white' }} onClick={handleYesButtonClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Loginpassword;
