import { createSlice } from "@reduxjs/toolkit"; 

const initialState = [];

const citySlice  = createSlice({
    name:"filter",
    initialState,
    reducers:{
        addcity: (state, action) =>{ 
            return state = action.payload;
        }, 
    }
})
export default citySlice.reducer; 
export const {addcity} = citySlice.actions;

