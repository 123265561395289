import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';


const Addon = (props) => {
    const [myaddons, setMyaddons] = useState([]);
    const [price, setPrice] = useState(0);

    const add = (e,p) => {
        if (myaddons.includes(e)) {
            setMyaddons(myaddons.filter(id => id !== e));
            setPrice(price-p)
        } else { 
            setMyaddons([...myaddons, e]);
            setPrice(price+p)
        }
    }

    useEffect(() => {
        props.getAddons(myaddons, price) 
    }, [myaddons])

    return (
        <>
            {props.value.map((item, ind) => ( 
                <div className="adoneservices_main_wrapper">
                    <div className="adoneservices_text">
                        <div className="adoneservices_producttitle">{props.value.name?.substr(0,100) }</div>
                        <div className="priductname">{item.description?.substr(0,100) }</div>
                        <div className="ruppee_text">€ {item.price}</div>
                    </div>
                    <div className="adoneservices_button" onClick={() => add(item.id,parseInt(item.price))}>
                        <div className="button_adone_main">
                            <button className="minmax_adonebtn btndesign" >{myaddons.includes(item.id) ? '-' : '+'}</button>
                            <span className='adonebtn btndesign'>{myaddons.includes(item.id) ? "Added" : "Add"}</span>
                        </div>
                    </div>
                    
                </div>
             
               
            ))}
          
        </>

    )
}

export default Addon
