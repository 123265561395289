import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for user login
export const loginUser = createAsyncThunk(
    "auth/loginUser",
    async (userData, { rejectWithValue }) => {
        try {

            const response = await axios.post(process.env.REACT_APP_API_URL + "/login", userData);

            return response;

        } catch (error) {

            if (error.response && error.response.status === 404) {
                return rejectWithValue(error.response.status);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);



export const logoutUser = createAsyncThunk(
    "auth/logoutUser",
    async (userData, { rejectWithValue }) => {
        try {
            // Clear local storage items related to authentication
            localStorage.clear();
            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('token');
            localStorage.removeItem('image');
            localStorage.removeItem('phone');
            // Return any required data, such as success message
            // const response = await Axios.post("/logout");
            return { success: true };
        } catch (error) {
            // Handle errors and return rejection with value
            return rejectWithValue(error.message);
        }
    }
);

export const registerUser = createAsyncThunk(
    "auth/registerUser",
    async (userData, { rejectWithValue }) => {
        try {

            const response = await axios.post(process.env.REACT_APP_API_URL + "/register", userData);

            return response;
        } catch (error) {

            if (error.response && error.response.status === 404) {
                return rejectWithValue(error.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


// Auth slice
const authSlice = createSlice({
    name: "auth",
    initialState: {
        id: localStorage.getItem("id"),
        name: localStorage.getItem("name"),
        token: localStorage.getItem("token"),
        image: localStorage.getItem("image"),
        phone: localStorage.getItem("phone"),
        payload: null,
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
    },
    reducers: {
        setUser: (state, action) => {
            const { id, name, token, image, phone } = action.payload;
            state.id = id;
            state.name = name;
            state.token = token;
            state.image = image;
            state.phone = phone;
        },
        clearUser: (state) => {
            state.user = null;
        },
        setGoogleUser: (state, action) => {
            console.log('action ', action)
            // state.user = action.payload;
            state.name = action.payload.name;
        },

    },
    extraReducers: {
        [loginUser.pending]: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = "";
        },
        [loginUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.payload = action.payload;
            state.token = action.payload.data.data.token;
            state.id = action.payload.data.data.id;
            state.name = action.payload.data.data.name;
            state.image = action.payload.data.data.image;
            state.phone = action.payload.data.data.phone;

            if (action.payload.data.data.token) {
                localStorage.setItem("token", action.payload.data.data.token);
                localStorage.setItem("id", action.payload.data.data.id);
                localStorage.setItem("name", action.payload.data.data.name);
                localStorage.setItem("image", action.payload.data.data.image);
                localStorage.setItem("phone", action.payload.data.data.phone);

            }
        },
        [loginUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload ? action.payload : "Something went wrong";
        },
        [logoutUser.pending]: (state) => {
            state.isLoading = true; // Set loading state
            state.isSuccess = false; // Reset success state
            state.isError = false; // Reset error state
            state.errorMessage = ""; // Clear error message
        },

        // Reducer for logoutUser.fulfilled action
        [logoutUser.fulfilled]: (state, action) => {
            state.isLoading = false; // Set loading state
            state.isSuccess = true; // Set success state
            state.name = null; // Reset user name
            state.token = null; // Reset authentication token
            state.image = null; // Reset user image
        },

        // Reducer for logoutUser.rejected action
        [logoutUser.rejected]: (state, action) => {
            state.isLoading = false; // Set loading state
            state.isError = true; // Set error state
            state.errorMessage = action.payload ? action.payload : "Something went wrong"; // Set error message
        },
        [registerUser.pending]: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = "";
        },
        [registerUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload.user; // assuming the response contains user data
        },
        [registerUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload ? action.payload.error : "Something went wrong";
        },
    },
});

export const { setUser, clearUser, setGoogleUser } = authSlice.actions;

export default authSlice.reducer;
