import Axios from '../../../Axios';
import React, { useEffect, useState } from 'react';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../../Style.css';
const Thankyou = () => {
    const [bookingData, setBookingData] = useState(null);
    const [date, setDate] = useState('');
    const dispatch = useDispatch();
    const { paymentId } = useParams();  // Extract paymentId from the URL

    useEffect(() => {
        const fetchBookingData = async () => {
            try {

                dispatch(updateLoader({ loader: true }));

                // Include paymentId in the API request URL path
                const params = {
                    order_id: paymentId
                };

                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/booking-confirmation?order_id=${paymentId}`);


                const booking = response.data.data.data;
                setBookingData(booking);

                const timestamp = booking.updated_at;
                const dateObj = new Date(timestamp);
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = dateObj.toLocaleDateString('en-US', options);
                setDate(formattedDate);

                dispatch(updateLoader({ loader: false }));
            } catch (error) {
                dispatch(updateLoader({ loader: false }));
                console.error('Error fetching booking data:', error);
            }
        };

        fetchBookingData();
    }, [dispatch, paymentId]);

    const handlePrint = () => {
        window.print();
    };

    if (!bookingData) {
        return <div></div>;
    }

    return (
        <>
            <div className='booking_confrim_pages'>
                <div className='container'>
                    <div className="yourtrip_wrapper_box_booking_confrim">
                        <div className='booking_confrim_title'>
                            <h3>
                                <img src='/icon/secssfull.png' className='img-fluid successfully_icon' alt="Success Icon" />
                                Great! Your Booking is confirmed
                            </h3>
                        </div>

                        <div className='booking_confrim_box_inner_wrapper'>
                            <div className='booking_confrim_picture mobile_booking'>
                                {bookingData.product.images[0].url ?
                                    <img src={`${bookingData.product.images[0].url}`} alt="Property Image" /> :
                                    <img src="/icon/checkoutimg.png" alt="Booking Confirmation" />
                                }
                            </div>
                            <div className="booking_text_confrim_idwrapper">
                                <div className='booking_id'>Order id: {bookingData.order_id}</div>
                                {/* <div className='booking_id'>Order id: {bookingData.id}</div> */}

                                <div className='booking_date'>Booked by {bookingData.user.name} on {date}</div>
                            </div>
                            <div className="booking_product_wrapper_confrim">
                                <div className='booking_confrim_box_rental'>
                                    <div className='booking_product_title'>
                                        <h6>{bookingData.product.name}</h6>
                                    </div>
                                    <div className='booking_product_dis'>
                                        <h6>{bookingData.product.description.split(' ').slice(0, 10).join(' ')}....</h6>
                                    </div>
                                </div>
                                <div className='booking_confrim_picture desktop_booking'>
                                    {bookingData.product.images[0].url ?
                                        <img src={`${bookingData.product.images[0].url}`} alt="Property Image" /> :
                                        <img src="/icon/checkoutimg.png" alt="Booking Confirmation" />
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <div className='bookedimg_watermark'>
                            <img src="/images/Group 250.png" className='img-fluid' alt="Watermark" />
                        </div> */}
                        <div className='detail_wrapper_details'>
                            <div className='direction_title'>
                                <h6>Detail</h6>
                            </div>
                            <div>
                                {/* <div className='booking_confrim_table'>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Primary Guest</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.user.name}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Check In</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.check_in}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Check Out</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.check_out}</h6>
                                        </div>
                                    </div>
                                </div> */}






                                {/* <div className='booking_confrim_table'>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Adults</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.number_of_guests}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Children</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.children}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Infant</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.infants}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Rooms</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.rooms}</h6>
                                        </div>
                                    </div>
                                </div>  */}



                                <div className='booking_confrim_table_secound'>
                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Primary Guest</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.user.name} </div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Email</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.user.email}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Status</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.status}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Total Amount</div>
                                        <div className='booking_confrimbooking_subtitle_title'>€{bookingData.total_amount}</div>
                                    </div>



                                </div>

                                <div className='booking_confrim_table_secound'>
                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Instant Discount -{bookingData.discount} % </div>
                                        <div className='booking_confrimbooking_subtitle_title'>€{bookingData.discount_price}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Service Fee</div>
                                        <div className='booking_confrimbooking_subtitle_title'>€{bookingData.service_fee}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Tax</div>
                                        <div className='booking_confrimbooking_subtitle_title'>€{bookingData.tax}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Payment Mode</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.payment_mode}</div>
                                    </div>

                                </div>



                                <div className='booking_confrim_table_secound'>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Check In</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.check_in}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Check In Time</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.product?.check_in_time}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Check Out</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.check_out}</div>
                                    </div>
                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Check Out Time</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.product?.check_out_time}</div>
                                    </div>

                                </div>



                                <div className='booking_confrim_table_secound'>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Adults</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.number_of_guests}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Children</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.children}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Infant</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.infants}</div>
                                    </div>

                                    <div className='booking_confrim_table_secound_inner_wrapper'>
                                        <div className='booking_confrimbooking_title'>Rooms</div>
                                        <div className='booking_confrimbooking_subtitle_title'>{bookingData.rooms}</div>
                                    </div>


                                </div>






                                {/* <div className='booking_confrim_table'>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Check In Time</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.product?.check_in_time}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Check In Time</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.product?.check_out_time}</h6>
                                        </div>
                                    </div>

                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Status</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.status}</h6>
                                        </div>
                                    </div>
                                    <div className='confrim_booking_width'>
                                        <div className='booking_confrim_details'>
                                            <h6>Total Amount</h6>
                                        </div>
                                        <div className='booking_confrim_details_title'>
                                            <h6>{bookingData.total_amount}</h6>
                                        </div>
                                    </div>
                                </div>  */}












                                {/* <div className='mt-2'>            
                                <div className='booking_confrim_footer_email'>
                                    <p>Email</p>
                                </div>
                                <div className='booking_confrim_footer_email_text'>
                                    <p><p>
                                        {bookingData.user.email}
                                    </p>
                                    </p>
                                </div>
                            </div>     */}
                                <div className="d-flex justify-content-end mt-3">
                                    <button className="print_btn" onClick={handlePrint}>Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Thankyou;
