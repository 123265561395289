import React from 'react'

const SingleRow = (props) => {
    return (
        <>
            <div className={`justify-content-between notifaction_wrapper ${props.item.is_read == 0 ? 'new-notification':""}`}> 
                <div className='notifaction-dot'></div>
                <div className="noti-header">
                    <div className="title_profile">
                        {props.item.notification_type}
                    </div>
                    <div className="date_natifaction">
                        {props.item.time}
                    </div>
                </div>
                <div className="value_profile">
                    {props.item.content}
                </div> 
            </div>
            {props.hr && <hr className='border-bottom_profile'/>}
        </>
    )
}

export default SingleRow