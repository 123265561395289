import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Redux/authSlice";
import filterSlice from "./Redux/filterSlice";
import categorySlice from "./Redux/categorySlice";
import countrySlice from "./Redux/countrySlice";
import citySlice from "./Redux/citySlice";
import staticSlice from "./Redux/staticSlice";
import notificationSlice from "./Redux/notificationSlice";
import commonSlice from "./Redux/commonSlice";
import loaderSlice from "./Redux/loaderSlice";

const store = configureStore({
  reducer: {
    user: authSlice,
    filers: filterSlice,
    category: categorySlice,
    country: countrySlice,
    city: citySlice,
    staticData: staticSlice,
    notification: notificationSlice,
    common: commonSlice,
    loader: loaderSlice,
  },
});

export default store;
