import React from 'react'

const Aboutus = () => {
  return (
    <div className='about_page_wrapper'>
    <div className="container">
         <h2 className='about_hedding_title'>About with web  dummy data</h2>
         <div className='about_text_wrapper'>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas laoreet ex ullamcorper massa tincidunt, in venenatis ex sagittis. Quisque quis mauris feugiat, maximus dolor et, maximus lectus. Cras dictum non eros eget fringilla. Quisque posuere arcu a elit blandit, nec mattis elit hendrerit. Etiam at augue ut nisi convallis lacinia. Curabitur aliquet massa at ullamcorper hendrerit. Pellentesque <br /><br /> et ultricies risus. Aenean volutpat leo vel ullamcorper posuere. Etiam id sollicitudin mi, egestas tempus nunc. Proin at egestas massa, vel faucibus elit. Vestibulum luctus arcu et quam <br />tincidunt, eget hendrerit felis hendrerit. Vivamus quam velit, convallis bibendum massa eget, varius fermentum diam. Donec at ipsum et sem venenatis porta sed sed purus. Phasellus vel ultrices justo. Ut lacinia egestas leo,<br /> ut fermentum risus interdum vitae. Suspendisse blandit convallis lorem, nec facilisis <br /><br />mauris iaculis sit amet.
        Phasellus id lobortis velit, sit amet ornare lorem. Integer congue ante nec ipsum elementum condimentum. Praesent a diam viverra, faucibus ipsum porta,<br /><br /> vehicula nunc. Praesent imperdiet ante felis, ut lobortis tellus semper ut. Maecenas eget euismod sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis sed scelerisque turpis, ut tempus tellus. Nam efficitur odio sit amet rhoncus iaculis. Maecenas accumsan ante fermentum, congue justo quis, molestie odio. Nunc<br /> at erat porttitor purus vestibulum bibendum in molestie urna. Suspendisse potenti. 
        </p>
        </div>
        <div className="property-card-about_list">
            <ul>
              <li>This spacious and well-lit apartment is located in the vibrant 
              downtown area, close to restaurants, shops, and entertainment venues.</li>
              <li>Rent: $1500/month</li>
              <li>Bedrooms: 2</li>
              <li>Bathrooms: 2</li>
              <li>Parking: Available</li>
              <li>Pets: Allowed</li>
            </ul>
            
        </div>
      <div className='about_text_wrapper'>
        <p>
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed a placerat turpis, viverra pellentesque elit. Cras nibh ipsum, tincidunt ut urna non, venenatis tincidunt augue. In ultrices scelerisque eros, id interdum turpis mattis eu. Pellentesque vitae metus molestie, tempor justo eget, ullamcorper nisl. <br />Ut posuere rutrum quam, sed consectetur arcu consectetur ac. Vivamus accumsan viverra justo, ac viverra sem feugiat vitae. Vestibulum sagittis fermentum leo. Vestibulum vehicula elit a libero scelerisque sollicitudin. Sed mollis imperdiet enim non pellentesque. </p><br />
       
        <p>
        Phasellus id lobortis velit, sit amet ornare lorem. Integer congue ante nec ipsum elementum condimentum. Praesent a diam viverra, faucibus ipsum porta, vehicula nunc. Praesent imperdiet ante felis, ut lobortis tellus semper ut. Maecenas eget euismod sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.  <br />Duis sed scelerisque turpis, ut tempus tellus. Nam efficitur odio sit amet rhoncus iaculis. Maecenas accumsan ante fermentum, congue justo quis, molestie odio. Nunc at erat porttitor purus vestibulum bibendum in molestie urna. Suspendisse potenti.

Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed a placerat turpis, viverra pellentesque elit. Cras nibh ipsum, tincidunt ut urna non, venenatis tincidunt augue. In ultrices scelerisque eros, id interdum turpis mattis eu. Pellentesque vitae metus molestie, tempor justo eget, ullamcorper nisl. Ut posuere rutrum quam, <br /> sed consectetur arcu consectetur ac. Vivamus accumsan viverra justo, ac viverra sem feugiat vitae. Vestibulum sagittis fermentum leo. Vestibulum vehicula elit a libero scelerisque sollicitudin. Sed mollis imperdiet enim non pellentesque.

Nam nec porttitor eros. Ut vel lectus et mauris sagittis egestas. In mollis nibh ut erat faucibus, et tempus magna iaculis. Donec eget cursus turpis, scelerisque porttitor quam. Aliquam et ipsum eget magna suscipit congue eu quis augue. Donec sed pellentesque est, non convallis ipsum. Integer erat felis, efficitur sit amet egestas egestas, <br /> semper eget magna. Maecenas dictum justo ac eros ornare, sit amet varius massa ultricies. Fusce ornare consequat libero nec dapibus. Morbi non leo eros. Suspendisse faucibus magna laoreet nulla mollis, ut vehicula felis pretium. Aenean odio libero,<br /><br /> ornare id sapien non, sodales <br /> tempor nibh. Donec vulputate vulputate scelerisque. Sed sollicitudin quam in placerat eleifend. Curabitur a nisi in risus maximus suscipit porta quis magna. Sed et metus nec nisi euismod bibendum.

Duis eget porttitor arcu. Vivamus a viverra sem, id vulputate diam. Donec tempor eleifend tellus, ut scelerisque diam. Fusce gravida purus vitae aliquam mattis. Donec rutrum quis libero et sodales. Donec ac lorem ut dolor hendrerit viverra eget quis nulla. Proin a justo euismod, posuere enim at, condimentum nisl. </p>
</div>
    </div>
    </div>
  )
}

export default Aboutus