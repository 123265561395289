import React, { useEffect, useState } from 'react'

const Radio = (props) => {
    const [hide, setHide] = useState(props.collapse)
    const [checkedValues, setCheckedValues] = useState(props.defaulselected);

    const handleCheckboxChange = (event) => {
      const { value } = event.target;
      props.getSelectedValue(value) 
      setCheckedValues(value); 
    };

    useEffect(()=>{
        if(checkedValues.length)
        {
            // props.getSelectedValue(checkedValues) 
        }
    },[checkedValues])

    useEffect(()=>{
        setHide(props.collapse)
    },[props.collapse])

    useEffect(()=>{ 
        if(props.reset==1)
        {  
            setCheckedValues([]);
        }
    },[props.reset])

    return (
        <>
        {props.data.length > 0 && (
            <div className="panel panel-default">
                <div
                    className="panel-heading filter_title_header"
                    role="tab"
                    id="headingOne"
                    onClick={() => setHide(!hide)}
                >
                    <h4 className="panel-title">
                        <a
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            {props.title}
                        </a>
                        <span class="minuplus">{hide?'-':'+'}</span>
                    </h4>
                </div>
                <div
                    className={`collapse ${hide && 'show'}`}
                    id="collapseExample"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                >
                    <div className="panel-body">
                        {props.data.map((item,ind)=> { 
                            return(
                            <div className="form-group filter_checkbox_btn">
                                <input 
                                    type={'radio'} 
                                    name={props.id}
                                    id={props.id+ind} 
                                    value={item.id}
                                    checked={checkedValues==item.id}
                                    onChange={handleCheckboxChange}
                                    className='filter_check'
                                    />
                                
                                <label className="filture_check_box filture_radio_box" htmlFor={props.id+ind}>{item.name}</label>
                            </div>
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default Radio