import { createSlice } from "@reduxjs/toolkit"; 

const initialState = [];

const countrySlice  = createSlice({
    name:"filter",
    initialState,
    reducers:{
        addCountry: (state, action) =>{ 
            return state = action.payload;
        }, 
    }
})
export default countrySlice.reducer; 
export const {addCountry} = countrySlice.actions;

