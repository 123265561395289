import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { updateLoader } from '../../Redux/loaderSlice';
import Axios from '../../Axios';
import Ratinglists from '../../Components/Ratinglists';

const Rating = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [showData, setShowData] = useState([]);
    const [links, setLinks] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const getData = async (id, page) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get(`/rating/${id}?page=${page}`)
            console.log(resp);
            if (resp.data.code == "EC200") {
                setShowData(resp.data.data.data.data);
                setLinks(resp.data.data.data.links)
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response)
            } else {
                console.log(error.message);
            }
            setShowData([])
        }
    }


    useEffect(() => {
        getData(id, 1);
    }, []);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>

            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link onClick={() => navigate(-1)} style={{ display: 'flex', justifyContent: 'left', margin: '2% 0', textDecoration: 'none' }}>
                            <div className='backarrowbtn'>
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className='profilebackbtn'>Rating
                            </div>
                        </Link>
                        <div className="card1 profile_section_wrapper">
                            {showData.map((item, ind) => (
                                <>
                                    <Ratinglists data={item} key={ind} />
                                    <hr className='border-bottom_profile' />
                                </>
                            ))}
                        </div>
                    </div>
                    {links && links.length > 0 && (
                        <div className='rating_pagination'>
                            <ul className="pagination">

                            </ul>
                            <div className='rating_pagination'>
                                <ul className="pagination">
                                    {links.map((item, ind) => (
                                        <li className="page-item" onClick={() => getData(id, parseInt(item.label))} style={{ display: (ind == 0 || links.length - 1 <= ind) ? 'none' : '' }} key={ind}><Link className={`page-link ${item.active ? 'pagination_active' : ''}`}>{item.label}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div >
                    )}
                </div>
            </div>
        </>
    )
}

export default Rating