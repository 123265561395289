import React, { useEffect, useState } from 'react';
import Axios from '../../../Axios';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

const TripDetail = () => {
    const [activeTab, setActiveTab] = useState('completed');
    const dispatch = useDispatch();
    const [showData, setShowData] = useState([]);
    const [property, setProperty] = useState();
    const [error, setError] = useState("");
    const [rating, setRating] = useState(5);
    const [ratingModel, setRatingModel] = useState(false);
    const [rate, setRate] = useState(null);
    const [reviews, setReviews] = useState("");
    const [uploadedImages, setUploadedImages] = useState([]);
    const [ratings, setRatings] = useState({});
    const { id } = useParams();
    const [data, setData] = useState()
    const [date, setDate] = useState('');
    const handleLoginClose = (data) => {
        setProperty(data);
        setRatingModel(!ratingModel);
    };

    const getData = async (type) => {
        setActiveTab(type);
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get(`/booking-lists?type=${type}`);
            if (resp.data.code === "EC200") {
                setShowData(resp.data.data.data); // Assuming response structure is { code: "EC200", data: { data: [] } }
                console.log(resp.data.data.data);
            } else {
                setShowData([]);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            setShowData([]);
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else if (error.response && error.response.status === 400) {
                console.log(error.message);
                setError("No Booking Found");
            } else {
                console.log(error.message);
            }
        }
    };

    useEffect(() => {
        getData("upcoming");
    }, []);

    const trimDescription = (description, wordLimit) => {
        const words = description.split(' ');
        const trimmedWords = words.slice(0, wordLimit);
        return trimmedWords.join(' ');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        return `${day} ${month}`;
    };

    const formatCreatedAt = (createdAt) => {
        const date = new Date(createdAt);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    const addReview = async (id, rate, review, images) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const formData = new FormData();
            formData.append('property_id', id);
            formData.append('rating', rate);
            formData.append('review', review);
            images.forEach(image => {
                formData.append('images', image);
            });

            const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/ratings`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
            });

            if (resp.data.code === "EC200") {
                console.log(resp.data.data);
                toast.success(`${resp.data.data.message}`);
                setRatingModel(false);
                setRate(null);
                setReviews("");
                setUploadedImages([]);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    };
    const confirmBookingOnMount = async (id) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const params = {
                order_id: id // Use id in your API call
            };

            const response = await Axios.post('/booking-confirmation', params);

            if (response.data.code === "EC200") {
                setData(response.data.data.data);
                const timestamp = response.data.data.data.updated_at;
                const dateObj = new Date(timestamp);
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = dateObj.toLocaleDateString('en-US', options);
                setDate(formattedDate);
                dispatch(updateLoader({ loader: false }));
            }
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    };


    useEffect(() => {

        // Call confirmBookingOnMount here when component mounts or based on specific conditions
        confirmBookingOnMount(id);
    }, [id]);


    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setUploadedImages(prevImages => [...prevImages, ...files]);
    };

    return (
        <div className='profile_section'>
            <div className='profile_wrapper'>
                <div className="container" style={{ marginTop: '2%' }}>
                    <Modal show={ratingModel} onHide={handleLoginClose} className='review_model_main_wrapper'>
                        <div className='review_model_header'>
                            <div className='reviewtitle-model'>Write Review</div>
                        </div>
                        <div className="review-form-wrapper">
                            <form>
                                <div className="rating-popup">
                                    <div className='rating_review-list_title'>How was the experience?</div>
                                    <div className="stars">
                                        <input type="radio" name="rating" id="star5" value="5" checked={rate === 5} onChange={() => setRate(5)} />
                                        <label htmlFor="star5" title="Very Good">★
                                            <div className='review_rating_name_title'>Very Good</div>
                                        </label>
                                        <input type="radio" name="rating" id="star4" value="4" checked={rate === 4} onChange={() => setRate(4)} />
                                        <label htmlFor="star4" title="Bad">★
                                            <div className='review_rating_name_title'>Good</div>
                                        </label>
                                        <input type="radio" name="rating" id="star3" value="3" checked={rate === 3} onChange={() => setRate(3)} />
                                        <label htmlFor="star3" title="Ok-Ok">★
                                            <div className='review_rating_name_title'>Ok-Ok</div>
                                        </label>
                                        <input type="radio" name="rating" id="star2" value="2" checked={rate === 2} onChange={() => setRate(2)} />
                                        <label htmlFor="star2" title="Bad">★
                                            <div className='review_rating_name_title'>Bad</div>
                                        </label>
                                        <input type="radio" name="rating" id="star1" value="1" checked={rate === 1} onChange={() => setRate(1)} />
                                        <label htmlFor="star1" title="Very Bad">★
                                            <div className='review_rating_name_title'>Very Bad</div>
                                        </label>
                                    </div>
                                </div>
                                <div className="photo-upload">
                                    <div className='rating_review-list_title'>Share Photos now!
                                        <div className='rating_review-list_sub_title'>Capture and add your Property experience with photos</div>
                                    </div>
                                    <div className='upload_images_list'>
                                        <ul>
                                            {uploadedImages.map((image, index) => (
                                                <li key={index}>
                                                    <img src={URL.createObjectURL(image)} className='img-responsive uploade-img-review' alt="uploaded" />
                                                    <div className='upload_images_list_clode_btn' onClick={() => setUploadedImages(uploadedImages.filter((_, i) => i !== index))}><i className='fa fa-close'></i></div>
                                                </li>
                                            ))}
                                            <li>
                                                <div className="upload-area-small">
                                                    <input type="file" id="photo-upload-small" accept="image/*" onChange={handleImageUpload} multiple />
                                                    <label htmlFor="photo-upload-small"><img src="/icon/plusupoad.png" className='img-fluid' alt="plus upload" /></label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="upload-area">
                                        <input type="file" id="photo-upload" accept="image/*" onChange={handleImageUpload} multiple />
                                        <label htmlFor="photo-upload"><img src="/icon/camera.png" className='img-fluid' alt="camera upload" /></label>
                                    </div>
                                </div>
                                <div className="review-text">
                                    <div className='rating_review-list_title'>Write Review</div>
                                    <textarea id="review" rows="4" value={reviews} onChange={(e) => setReviews(e.target.value)}></textarea>
                                </div>
                                <button type="button" className='btn review-popup-btn' onClick={() => addReview(property.property_id, rate, reviews, uploadedImages)}>Submit</button>
                            </form>
                        </div>
                    </Modal>
                    <Link to="/user/trips" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none', marginBottom: '2%' }}>
                        <div className='backarrowbtn'>
                            <i className="fa fa-long-arrow-left"></i>
                        </div>
                        <div className="profilebackbtn" style={{ marginTop: '2px' }}>Trips</div>
                    </Link>
                    {data && (
                        <div className='trippage_main_wrapper'>
                            <div className='tripbooking_id_wrapper'>
                                <div className='tripbookingid'>Booking Id : #{data.id}</div>
                                <div className='tripbookingid_datetime'>Cancelled by {data.user.name} on {date}</div>
                            </div>
                            <div className='trip_details_flex_wrapper'>
                                <div className='trips_details_left'>
                                    <Link to={`/product/${data.product.id}`} className='cancellation_title_tripdetails'>
                                        {data.product.name}
                                    </Link>

                                    <div className='direction_title_wrapper'>
                                        <div className='trips_details_product_dis'>{trimDescription(data.product.description, 10)}...</div>
                                    </div>
                                </div>
                                <div className='trips_details_right'>
                                    <div className='trips_details_pitcure'>
                                        <img
                                            src={`${data.product.images[0].url}`}
                                            className='img-fluid'
                                            alt="product"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='trips_details_booking_details'>
                                <div className='trips_booking_details_wrappar'>
                                    <div className='cancellation_title_tripdetails'>Booking Details</div>
                                    <div className='trips_details_booking_details_flex'>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Primary Guest</div>
                                            <div className='booking_details_sub_title_trips'>{data.user.name}</div>
                                        </div>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Check In</div>
                                            <div className='booking_details_sub_title_trips'>{data.check_in}</div>
                                        </div>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Check In Time</div>
                                            <div className='booking_details_sub_title_trips'>{data.product.check_in_time}</div>
                                        </div>
                                    </div>
                                    <div className='trips_details_booking_details_flex'>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Adult</div>
                                            <div className='booking_details_sub_title_trips'>{data.number_of_guests}</div>
                                        </div>

                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Check Out</div>
                                            <div className='booking_details_sub_title_trips'>{data.check_out}</div>
                                        </div>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Check Out Time</div>
                                            <div className='booking_details_sub_title_trips'>{data.product.check_out_time}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='trips_details_booking_details_flex'>
                                    <div className='trips_details_booking_clumn'>
                                        <div className='booking_details_title_trips'>Children</div>
                                        <div className='booking_details_sub_title_trips'>{data.children}</div>
                                    </div>
                                    <div className='trips_details_booking_clumn'>
                                        <div className='booking_details_title_trips'>E-mail</div>
                                        <div className='booking_details_sub_title_trips'>{data.user.email}</div>
                                    </div>
                                </div>
                                <div className='trip_details_payemnt_mainwrapper'>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Account Holder Name</div>
                                        <div className='details_trip_right_price'>{data.account_holder_name}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Bank Name</div>
                                        <div className='details_trip_right_price'>{data.bank_name}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Bic</div>
                                        <div className='details_trip_right_price'>{data.bic}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Ibn</div>
                                        <div className='details_trip_right_price'>{data.ibn}</div>
                                    </div>
                                </div>
                                <div className='trip_details_payemnt_mainwrapper'>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>{data.product_price}X {data.book_day} Nights</div>
                                        <div className='details_trip_right_price'>€{data.calculated_price}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Instant Discount - {data.discount} %</div>
                                        <div className='details_trip_right_price'>€{data.discount_price}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Service fee</div>
                                        <div className='details_trip_right_price'>€{data.service_fee}</div>
                                    </div>
                                    {data.addons_price > 0 && <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Addons</div>
                                        <div className='details_trip_right_price'>€{data.addons_price}</div>
                                    </div>}
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Tax</div>
                                        <div className='details_trip_right_price'>€{data.tax}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Total Refund Amount</div>
                                        <div className='details_trip_right_price'>€{data.refund_price}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Payment Mode</div>
                                        <div className='details_trip_right_price'>{data.payment_mode}</div>
                                    </div>
                                    <div className='trip_page_details_payemnt_wrapper'>
                                        <div className='details_trip_left_price_title'>Total Price</div>
                                        <div className='details_trip_right_price'>€{data.total_amount}</div>
                                    </div>
                                </div>

                            </div>
                        </div>)}
                </div>
            </div>
        </div>
    )
}

export default TripDetail;
