import React, { useEffect, useState } from 'react'
import PaymentHistory from '../../../Components/PaymentHistory'
import { Link } from 'react-router-dom'
import Axios from '../../../Axios'
import { updateLoader } from '../../../Redux/loaderSlice'
import { useDispatch } from 'react-redux'

const Order = () => {
    const [showData, setShowData] = useState([]);
    const dispatch = useDispatch();
    const getData = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/payment-lists')
            if (resp.data.code === "EC200") {
                setShowData(resp.data.data.data.data);
                console.log(resp.data.data.data.data)
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response)
            } else {
                console.log(error.message);
            }
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className="backarrowbtn"><i className="fa fa-long-arrow-left"></i></div>
                            <div className="profilebackbtn">Payments
                            </div>
                        </Link>
                        <div className="card1 profile_section_wrapper">
                            {showData.length > 0 ? (
                                showData.map((item, ind) => (
                                    <React.Fragment key={ind}>
                                        <PaymentHistory title="payment" amount="rs10" data={item} showPrice={true} />
                                        <hr className='border-bottom_profile' />
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className='no-data_dummy-text'>No Orders</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Order
