import React from 'react'
 
const Countinc = ({title,update,value}) => { 
    const updateFn = (e) => {
        if(e>=0)
        {
            update(e)
        }
    }
  return (
    <div className='row1  wapper-drop-down'>
        <div className='col1-4'>{title}
        <p className='subtitle_ault_dropdown'>{title} 1 or above</p>
        </div>
        <div className='number_adult_num_min_wrapper'>
        <div className='col1-2'onClick={()=>updateFn(value-1)}><div className='number_button'>-</div></div>
        <div className='col-21'><div className='numbervaluespeace'>{value}</div></div>
        <div className='col-21 'onClick={()=>updateFn(value+1)}> <div className='number_button'>+</div></div>
        </div>
    </div>
  )
}

export default Countinc