import React from 'react'
import Loginpassword from '../../../Components/Loginpassword'
import { Link } from 'react-router-dom'

const Loginandsecurity = () => {
    return (
        <>
            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className="backarrowbtn"><i className="fa fa-long-arrow-left"></i></div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>Login and Security
                            </div>
                        </Link>
                        <div className="card1 profile_section_wrapper" style={{ padding: '10px 16px' }}>
                            <Loginpassword title="Password" description="  " todo="Update" />
                            <hr className='border-bottom_profile' />
                            <Loginpassword title="Account" description="Delete your account" todo="Delete" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loginandsecurity
