import React, { useEffect, useState } from 'react';
import Axios from '../../../Axios';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

const OrderDetail = () => {
    const [activeTab, setActiveTab] = useState('completed');
    const dispatch = useDispatch();
    const [showData, setShowData] = useState(null);
    const [error, setError] = useState("");
    const { id } = useParams();

    const getData = async (type) => {
        setActiveTab(type);
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get(`/payment-lists/${id}`);
            if (resp.data.code === "EC200") {
                setShowData(resp.data.data.data); // Assuming response structure is { code: "EC200", data: { data: [] } }
                console.log(resp.data.data.data);
            } else {
                setShowData(null);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            setShowData(null);
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else if (error.response && error.response.status === 400) {
                console.log(error.message);
                setError("No Booking Found");
            } else {
                console.log(error.message);
            }
        }
    };

    useEffect(() => {
        getData(activeTab);
    }, [id]);

    const trimDescription = (description, wordLimit) => {
        const words = description.split(' ');
        const trimmedWords = words.slice(0, wordLimit);
        return trimmedWords.join(' ');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    return (
        <div className="container" style={{ marginTop: '2%' }}>
            <div className=''>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none', marginBottom: '2%' }}>
                            <div className='backarrowbtn'>
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>
                                Payment Details
                            </div>
                        </Link>
                        {showData && (
                            <div className='trippage_main_wrapper'>
                                <div className='tripbooking_id_wrapper'>
                                    <div className='tripbookingid'>Booking Id : #{showData.id}</div>
                                    <div className='tripbookingid_datetime'>Booked by {showData.user.name} on {formatDate(showData.created_at)}</div>
                                </div>
                                <div className='trip_details_flex_wrapper'>
                                    <div className='trips_details_left'>
                                        <Link to={`/product/${showData.product.id}`} className='cancellation_title_tripdetails'>
                                            {showData.product.name}
                                        </Link>
                                        <div className='direction_title_wrapper'>
                                            <div className='trips_details_product_dis'>{trimDescription(showData.product.description, 10)}...</div>
                                        </div>
                                    </div>
                                    <div className='trips_details_right'>
                                        <div className='trips_details_pitcure'>
                                            <img
                                                src={`${showData.product.images[0].url}`}
                                                className='img-fluid'
                                                alt="product"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='trips_details_booking_details'>
                                    <div className='trips_booking_details_wrappar'>
                                        <div className='cancellation_title_tripdetails'>Booking Details</div>
                                        <div className='trips_details_booking_details_flex'>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Primary Guest</div>
                                                <div className='booking_details_sub_title_trips'>{showData.user.name}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check In</div>
                                                <div className='booking_details_sub_title_trips'>{formatDate(showData.check_in)}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check In Time</div>
                                                <div className='booking_details_sub_title_trips'>{showData.product.check_in_time}</div>
                                            </div>
                                        </div>
                                        <div className='trips_details_booking_details_flex'>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Adult</div>
                                                <div className='booking_details_sub_title_trips'>{showData.number_of_guests}</div>
                                            </div>

                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check Out</div>
                                                <div className='booking_details_sub_title_trips'>{formatDate(showData.check_out)}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check Out Time</div>
                                                <div className='booking_details_sub_title_trips'>{showData.product.check_out_time}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='trips_details_booking_details_flex'>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Children</div>
                                            <div className='booking_details_sub_title_trips'>{showData.children}</div>
                                        </div>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>E-mail</div>
                                            <div className='booking_details_sub_title_trips'>{showData.user.email}</div>
                                        </div>
                                    </div>
                                    <div className='trip_details_payemnt_mainwrapper' style={{ paddingRight: '3%' }}>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>€{showData.product_price} X {showData.book_day} Nights</div>
                                            <div className='details_trip_right_price'>€{showData.calculated_price}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Instant Discount - {showData.discount} %</div>
                                            <div className='details_trip_right_price'>-€{showData.discount_price}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Service fee</div>
                                            <div className='details_trip_right_price'>€{showData.service_fee}</div>
                                        </div>
                                        {showData.addons_price > 0 && <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Addons</div>
                                            <div className='details_trip_right_price'>€{showData.addons_price}</div>
                                        </div>}
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Tax</div>
                                            <div className='details_trip_right_price'>€{showData.tax}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Payment Status</div>
                                            <div className='details_trip_right_price'>{showData.payment_status}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Total Price</div>
                                            <div className='details_trip_right_price'>€{showData.total_amount}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetail;
