import { useSelector } from "react-redux";


export const getTodayDate = (e) => {
    const today = new Date();
    if (e) {
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + e);
        const tomorrowYear = tomorrow.getFullYear();
        const tomorrowMonth = tomorrow.getMonth() + 1;
        const tomorrowDay = tomorrow.getDate();
        const formattedMonth = tomorrowMonth < 10 ? `0${tomorrowMonth}` : tomorrowMonth;
        const formattedDay = tomorrowDay < 10 ? `0${tomorrowDay}` : tomorrowDay;
        return `${tomorrowYear}-${formattedMonth}-${formattedDay}`;
    } else {
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1;
        const todayDay = today.getDate();
        const formattedMonth = todayMonth < 10 ? `0${todayMonth}` : todayMonth;
        const formattedDay = todayDay < 10 ? `0${todayDay}` : todayDay;
        return `${todayYear}-${formattedMonth}-${formattedDay}`;
    }
};

export const getFormatDate = (e) => {
    var dateString = new Date(e);
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    return year + "-" + month + "-" + day;
}

function calculatePayablePrice(price, discountPercentage) { 
     let discountAmount = price * (discountPercentage / 100);
     return discountAmount;  
}
function calculateDiffrenaceBeetwenDate(check_in,check_out)
{ 
    const startDate = new Date(check_in);
    const endDate = new Date(check_out); 
    const differenceMs = endDate - startDate; 
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)); 
    return differenceDays;
}
export const getCheckoutPageDetails = (check_in,check_out,price,discout,servicefee,tax) => 
{ 
    const differenceDays = calculateDiffrenaceBeetwenDate(check_in,check_out)
    let totalPrice= price*differenceDays; 
    let discountAmount = calculatePayablePrice(totalPrice,discout) 
    let payablePrice = ((totalPrice+ servicefee + tax) - discountAmount); 
    return {differenceDays,totalPrice,discountAmount,servicefee,tax, payablePrice};
}

 