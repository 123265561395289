import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
}

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        updateLoader: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})
export default loaderSlice.reducer;
export const { updateLoader } = loaderSlice.actions;

