import "./App.css";
import "./Style.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home/Home";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import Product from "./Page/Product/Product";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import Forgetpassword from "./Page/Password/Forgetpassword";
import Productfiler from "./Page/Product/Productfiler";
import Profile from "./Page/User/Profile/Profile";
import Notification from "./Page/User/Notification/Notification";
import Checkout from "./Page/User/Checkout/Checkout";
import Order from "./Page/User/Order/Order";
import Trips from "./Page/User/Trips/Trips";
import Loginandsecurity from "./Page/User/Securty/Loginandsecurity";
import PrivacyPolicy from "./Page/PrivacyPolicy/PrivacyPolicy";
import Help from "./Page/Help/Help";
import Thankyou from "./Page/User/Checkout/Thankyou";
import Wishlists from "./Page/User/Wishlists/Wishlists";
import Rating from "./Page/Product/Rating";
import Aboutus from "./Page/About/Aboutus";
import GoogleCallback from "./GoogleCallback";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import EmailReset from "./Page/Email/EmailReset"
import PropertyRequest from "./Components/PropertyRequest";
import TripDetail from "./Page/User/Trips/TripDetail";
import TripsCancel from "./Page/User/Trips/TripsCancel";
import TripsCancelled from "./Page/User/Trips/TripsCancelled";
import Failed from "./Page/User/Checkout/Failed";
import OrderDetail from "./Page/User/Order/OrderDetail"; 
function App() {
  const isAuthenticated = useSelector((state) => state.user.token);
  return (
    <><ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
      <Router>
        <Header />
        <div  >
          <Routes> 
            <Route path="/" exact element={<Home />} />
            <Route path="/form" exact element={<PropertyRequest />} />
            <Route path="/product/:id" exact element={<Product />} />
            <Route path="/product-rating/:id" exact element={<Rating />} />
            <Route path="/product-filter" exact element={<Productfiler />} />
            <Route path="/forget-password/:token" exact element={<Forgetpassword />} />
            <Route path="/email-verification/:token" exact element={<EmailReset />} />
            <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
            <Route path="/help" exact element={<Help />} />
            <Route path="/about-us" exact element={<Aboutus />} />
            <Route path="/auth/google" exact element={<GoogleCallback />}></Route>
            <Route path="thankyou/:paymentId" exact element={<Thankyou />} />
            <Route path="failed/:paymentId" exact element={<Failed />} />

            <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
              <Route path="user/profile" exact element={<Profile />} />
              <Route path="user/notification" exact element={<Notification />} />
              <Route path="user/checkout" exact element={<Checkout />} />

              <Route path="user/orders" exact element={<Order />} />
              <Route path="user/orders/:id" exact element={<OrderDetail />} />
              <Route path="user/wishlists" exact element={<Wishlists />} />
              <Route path="user/trips" exact element={<Trips />} />
              <Route path="user/trips/:id" exact element={<TripDetail />} />
              <Route path="user/trips_cancel/:id" exact element={<TripsCancel />} />
              <Route path="user/trips_cancelled/:id" exact element={<TripsCancelled />} />

              <Route path="user/change" exact element={<Loginandsecurity />} />
            </Route>
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
