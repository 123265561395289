import { createSlice } from "@reduxjs/toolkit"; 

const initialState = 0;

const notificationSlice  = createSlice({
    name:"notificationData",
    initialState,
    reducers:{
        addNoti: (state, action) =>{ 
            return state = action.payload;
        }, 
    }
})
export default notificationSlice.reducer; 
export const {addNoti} = notificationSlice.actions;

