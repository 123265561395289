import { createSlice } from "@reduxjs/toolkit"; 

const initialState = [];

const staticSlice  = createSlice({
    name:"staticData",
    initialState,
    reducers:{
        addStatic: (state, action) =>{ 
            return state = action.payload;
        }, 
    }
})
export default staticSlice.reducer; 
export const {addStatic} = staticSlice.actions;

