import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import Axios from '../../../Axios';
import axios from 'axios';
import { toast } from 'react-toastify';

const Trips = () => {
    const [showData, setShowData] = useState([]);
    const [activeTab, setActiveTab] = useState('upcomming');
    const dispatch = useDispatch();
    const [ratings, setRatings] = useState({});
    const [property, setProperty] = useState();
    const [rating, setRating] = useState(5);
    const [reviews, setReviews] = useState("");
    const [ratingModel, setRatingModel] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [rate, setRate] = useState(null);
    const [error, setError] = useState("");
    const [selectedTrip, setSelectedTrip] = useState(null);
    const navigate = useNavigate();
    const getData = async (type) => {
        setActiveTab(type);
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get(`/booking-lists?type=${type}`);
            if (resp.data.code === "EC200") {

                setShowData(resp.data.data.data); // Assuming response structure is { code: "EC200", data: { data: [] } }
                console.log(resp.data.data.data);
            }
            else {
                setShowData([])
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            setShowData([])
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else if (error.response && error.response.status === "EC400") {
                console.log(error.message);
                setError("No Booking Found");
            } else {
                console.log(error.message);
            }
        }
    };

    useEffect(() => {
        getData("upcomming");
    }, []);

    // Function to trim the description to the first 6 words
    const trimDescription = (description, wordLimit) => {
        const words = description.split(' '); // Split the description into words
        const trimmedWords = words.slice(0, wordLimit); // Take the first 6 words
        return trimmedWords.join(' '); // Join the words back into a string
    };

    // Function to format dates
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with zero if needed
        const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
        return `${day} ${month}`;
    };

    // Function to format date ranges
    const formatDateRange = (checkIn, checkOut) => {
        const formattedCheckIn = formatDate(checkIn);
        const formattedCheckOut = formatDate(checkOut);
        const year = new Date(checkIn).getFullYear();
        return `${formattedCheckIn} - ${formattedCheckOut}, ${year}`;
    };

    // Function to format the created_at date
    const formatCreatedAt = (createdAt) => {
        const date = new Date(createdAt);
        const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with zero if needed
        const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    const addReview = async (id, rate, review, images) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const formData = new FormData();
            formData.append('property_id', id);
            formData.append('rating', rate);
            formData.append('review', review);
            images.forEach(image => {
                formData.append('images', image);
            });

            const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/ratings`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                }
            });

            if (resp.data.code === "EC200") {
                console.log(resp.data.data);
                toast.success(`${resp.data.data.message}`);
                setRatingModel(false);
                setRate(null);
                setReviews("");
                setUploadedImages([]);
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    };

    const handleLoginClose = (data) => {
        setProperty(data);
        setRatingModel(!ratingModel);
    };

    const handleReview = async (index) => {
        const property = showData[index];
        if (!property) return; // Exit if no property is found

        const ratingValue = ratings[index];
        if (!ratingValue) return; // Exit if no rating is selected

        addReview(property.property_id, ratingValue, reviews, uploadedImages);
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setUploadedImages(prevImages => [...prevImages, ...files]);
    };
    const handleTripClick = (trip) => {
        if (activeTab === 'completed') {
            setSelectedTrip(trip);
            navigate(`/user/trips/${trip.order_id}`);
        }
        else if (activeTab === 'upcomming') {
            navigate(`/user/trips_cancel/${trip.order_id}`)
        }
        else {
            navigate(`/user/trips_cancelled/${trip.order_id}`);
        }
    };
    const cancelBooking = async (trip) => {

    };

    return (
        <>

            <div className='profile_section'>
                <div className='profile_wrapper'>
                    <div className='container'>
                        <Link to="/" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none' }}>
                            <div className='backarrowbtn'>
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>Trips</div>
                        </Link>
                        <div className='trip_list_btn'>
                            <ul>
                                <li onClick={() => getData('upcomming')} className={activeTab === 'upcomming' ? 'active1' : ''}>Upcoming</li>
                                <li onClick={() => getData('completed')} className={activeTab === 'completed' ? 'active1' : ''}>Completed</li>
                                <li onClick={() => getData('cancel')} className={activeTab === 'cancel' ? 'active1' : ''}>Cancelled</li>
                            </ul>
                        </div>
                        {error ? (
                            <div className="d-flex justify-content-center no-data_dummy-text" style={{ fontSize: '19px' }}>
                                {error}
                            </div>
                        ) : (
                            showData.length > 0 ? (
                                showData.map((data, index) => (
                                    <div className="trippage_main_wrapper" key={index} onClick={() => handleTripClick(data)}>
                                        <div className="trippage_inner_wrapper">
                                            <div className="tripe_left_bar">
                                                <div className="trips_picture_new">
                                                    {data.product.images[0].url ? (
                                                        <img
                                                            src={`${data.product.images[0].url}`}
                                                            alt="product"
                                                        />
                                                    ) : (
                                                        <img
                                                            src="https://previews.123rf.com/images/nadianb/nadianb1709/nadianb170900256/86443804-idyllic-landscape-in-mountains-rural-scene-beautiful-nature-image-toned-square.jpg"
                                                            alt="logo"
                                                        />
                                                    )}
                                                </div>
                                                <div className="trips_contant_data">
                                                    <Link to={`/product/${data.product.id}`} className='cancellation_title_tripdetails'>
                                                        {data.product.name}
                                                    </Link>
                                                    <div className="value_profile_date">{formatDateRange(data.check_in, data.check_out)}</div>
                                                    <div className="value_profile">{trimDescription(data.product.description, 6)}</div>

                                                </div>
                                            </div>
                                            <div className="tripe_right_bar">
                                                <div className="booking_id">ID: {data.id}</div>
                                            </div>
                                        </div>
                                        <div className="booked_status_main_wrapper">
                                            <div className="booked_status_inner_wrapper">
                                                <div className="book_status_date_left">
                                                    <div className="booked_text_title">
                                                        Booked On:<span className="booked_date">{formatCreatedAt(data.created_at)}</span>
                                                    </div>
                                                </div>
                                                <div className="book_status_date_right">
                                                    <div className="boked_status">
                                                        Status:
                                                        <span
                                                            className={
                                                                data.status.toLowerCase() === 'completed'
                                                                    ? 'completed_text'
                                                                    : data.status.toLowerCase() === 'failed'
                                                                        ? 'completed_failed'
                                                                        : ''
                                                            }
                                                            style={activeTab === 'cancel' ? { color: 'red' } : {}}
                                                        >
                                                            {activeTab === 'upcomming' ? 'Upcoming' : activeTab === 'cancel' ? ' Cancelled' : activeTab === 'completed' ? 'Completed' : activeTab}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        {activeTab === 'completed' && (
                                            <div className="rating_star_main_wrapper">
                                                <div className="rating_star_inner_wrapper">
                                                    <div className="right_addfeedback">
                                                        {/* <div className="addfeedback_title" onClick={() => handleLoginClose(data)}>
                                                            Add Feedback
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="d-flex justify-content-center no-data_dummy-text" style={{ fontSize: '19px' }}>
                                    No Trips
                                </div>
                            )
                        )}
                    </div>
                </div>




            </div>

            {/* <div className='trip_details_main_wrapper trippage_main_wrapper'>

                <div className='tripbooking_id_wrapper'>
                    <div className='tripbookingid'>Booking Id : #00000000</div>
                    <div className='tripbookingid_datetime'>Booked by Loreum on Friday, 1 March 2024</div>
                </div>

                <div className='trip_details_flex_wrapper'>
                    <div className='trips_details_left'>
                        <div className='trips_details_product_name'>Farm Stay In New Delhi, India</div>
                        <div className='trips_details_product_dis'>Secluded Mountain Escape- Entire House</div>

                        <div className='direction_title_wrapper'>
                            <div className='cancellation_title_tripdetails'>Direction</div>
                            <div className='trips_details_product_dis'>This is king vila from india</div>
                        </div>
                    </div>

                    <div className='trips_details_right'>
                        <div className='trips_details_pitcure'><img
                            src="/23edd121-64b7-4d6e-b1eb-8d78e629aada.jpg"
                            className='img-fluid' /></div>
                    </div>
                </div>


                <div className='trips_details_booking_details '>
                    <div className='trips_booking_details_wrappar'>
                        <div class="cancellation_title_tripdetails">Bookig Details</div>
                        <div className='trips_details_booking_details_flex'>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Primary Guest</div>
                                <div className='booking_details_sub_title_trips'>Vedprakash sharma</div>
                            </div>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Check In</div>
                                <div className='booking_details_sub_title_trips'>2024-07-09</div>
                            </div>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Check In Time</div>
                                <div className='booking_details_sub_title_trips'>2024-07-10</div>
                            </div>
                        </div>
                        <div className='trips_details_booking_details_flex'>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Guest</div>
                                <div className='booking_details_sub_title_trips'>20</div>
                            </div>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Check In</div>
                                <div className='booking_details_sub_title_trips'>2024-07-09</div>
                            </div>
                            <div className='trips_details_booking_clumn'>
                                <div className='booking_details_title_trips'>Check In Time</div>
                                <div className='booking_details_sub_title_trips'>2024-07-10</div>
                            </div>
                        </div>
                    </div>
                    <div className='trips_details_booking_details_flex'>
                        <div className='trips_details_booking_clumn'>
                            <div className='booking_details_title_trips'>E-mail</div>
                            <div className='booking_details_sub_title_trips'>vedprakashsharmaald@gmail.com</div>
                        </div>

                    </div>

                    <div className='trip_details_payemnt_mainwrapper'>
                        <div className='trip_page_details_payemnt_wrapper'>
                            <div className='details_trip_left_price_title'>Total Price</div>
                            <div className='details_trip_right_price'>€ 30
                                <div className='view-breakup_text'>View breakup</div>
                            </div>
                        </div>
                        <div className='trip_page_details_payemnt_wrapper'>
                            <div className='details_trip_left_price_title'>Payment Mode</div>
                            <div className='details_trip_right_price'>Paypal</div>
                        </div>
                    </div>

                    <div className='cancellation_main_wrapper_trip_details'>
                        <div className='cancellation_title_tripdetails'>Cancellation policy</div>
                        <div className='trip-details_cancellation_wrapper'>
                            <div className='trip-details_cancellation_title'>Free cancellation before 12:00 pm on 2Mar.</div>
                            <div className='trip-details_cancellation_subtitle'>Cancel before check-in on 3 Mar for a partial
                                refund.Learn more</div>
                        </div>
                        <div className='trip-details_cancellation_wrapper'>
                            <div className='trip-details_cancellation_title'>No Refund after 3 March, 9:00 am</div>
                            <div className='trip-details_cancellation_subtitle'>100% of booking amount will be charged upon
                                cancellation.</div>
                        </div>
                    </div>


                    <a href="#" className='cancel_trip_details_btn'>Cancel Booking</a>
                </div>




            </div> */}



        </>
    );
};

export default Trips;
